import { cn } from "../foundation/misc";
import { Spinner } from "./spinner";
import { Symbol } from "./symbol";

export function PInputTile({
  children,
  active,
  onClick,
  className,
  ...others
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
}) {
  return (
    <button
      className={cn(
        "flex flex-row items-center justify-center font-semibold border border-opacity-40 rounded-lg outline-complementary outline-offset-2 box-border relative min-w-0 h-[34px]",
        active && "border-primary bg-primary text-white border-opacity-100",
        className,
      )}
      {...others}
    >
      {children}
    </button>
  );
}

export function Button({
  big,
  outline,
  disabled,
  success,
  failure,
  loading,
  children,
  className,
  AltComponent,
  ...others
}: {
  big?: boolean;
  outline?: boolean;
  disabled?: boolean;
  success?: boolean;
  failure?: boolean;
  loading?: boolean;
  AltComponent?: React.ComponentType<any>;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  Record<string, any>) {
  const finalClassName = cn(
    "flex flex-row items-center justify-center font-semibold  rounded-lg outline-complementary outline-offset-2 box-border relative min-w-0 select-none",
    big ? "px-[34px] h-[52px]" : "px-[25px] h-[44px]",
    outline && "border border-primary outline-offset-[3px]",
    !outline && "bg-primary",
    disabled || outline ? "text-primary" : "text-white",
    !outline &&
      !disabled &&
      "hover:bg-gradient-to-r hover:from-secondary hover:to-primary active:from-secondary active:to-secondary",
    outline &&
      !disabled &&
      "hover:bg-primary hover:bg-opacity-10 active:bg-transparent",
    disabled && "bg-opacity-10 text-opacity-40 border-opacity-40",
    success && disabled && "bg-success text-success text-opacity-100",
    failure && disabled && "bg-error text-error text-opacity-100",
    className,
  );

  const finalChildren = (
    <>
      {" "}
      {loading && (
        <Spinner
          className={cn(
            "w-6 h-6 absolute m-auto left-0 right-0 top-0 bottom-0",
            outline || disabled ? "fill-primary" : "fill-white",
            disabled && "opacity-40",
          )}
        />
      )}
      <div
        className={cn(
          loading && "invisible",
          "inline-block justify-center overflow-hidden text-nowrap text-ellipsis min-w-0",
        )}
      >
        {children}
      </div>
    </>
  );

  if (AltComponent) {
    return (
      <AltComponent disabled={disabled} className={finalClassName} {...others}>
        {finalChildren}
      </AltComponent>
    );
  } else if (!Object.keys(others).length) {
    return <div className={finalClassName}>{finalChildren}</div>;
  } else {
    return (
      <button disabled={disabled} className={finalClassName} {...others}>
        {finalChildren}
      </button>
    );
  }
}

export function CircularIconButton({
  children,
  className,
  ...others
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <IconButton
      className={cn("rounded-full aspect-square p-2.5 border  border-primary ")}
      {...others}
    >
      {children}
    </IconButton>
  );
}

export function CircularSymbolButton({
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <CircularIconButton {...props}>
      <Symbol className="text-primary font-light">{props.children}</Symbol>
    </CircularIconButton>
  );
}

export function IconButton({
  children,
  className,
  ...others
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={cn(
        "outline-primary outline-offset-2 flex",
        !others.disabled && "hover:bg-primary/10",
        others.disabled && "opacity-40",
        className,
      )}
      {...others}
    >
      {children}
    </button>
  );
}

export function EmojiButton({
  children,
  className,
  ...others
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <IconButton
      className={cn("text-[32px] leading-[32px] w-[32px] h-[32px]", className)}
      {...others}
    >
      {children}
    </IconButton>
  );
}

export function FooterButton({
  children,
  className,
  ...others
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={cn("text-foreground text-opacity-70", className)}
      {...others}
    >
      {children}
    </button>
  );
}

export function PInputChip({
  checked,
  setChecked,
  children,
  className,
  indicator,
  ...others
}: React.ButtonHTMLAttributes<HTMLLabelElement> & {
  checked: boolean;
  indicator?: boolean;
  setChecked: (checked: boolean) => void;
}) {
  return (
    <label
      className={cn(
        "flex flex-row items-center font-medium hover:cursor-pointer outline-primary focus-within:outline-2 focus-within:outline select-non",
      )}
      onClick={(e) => {
        e.preventDefault();
        setChecked(!checked);
      }}
      {...others}
    >
      <input
        className="appearance-none"
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(!checked);
        }}
      />
      <div
        className={cn(
          "rounded-full border-opacity-40 border py-2 px-4 text-[13px] hover:bg-foreground/5 relative bg-white",
          checked &&
            "border-primary bg-primary text-white  hover:bg-primary/70 hover:border-transparent",
        )}
      >
        {indicator && (
          <div className="absolute -right-[1px] -top-[1px] w-[12px] h-[12px] rounded-full bg-error" />
        )}
        <p className={cn("opacity-70", checked && "opacity-100")}>{children}</p>
      </div>
    </label>
  );
}

export function RadioButtonGroup<T extends string>({
  options,
  selectedOption,
  setSelectedOption,
}: {
  options: T[];
  selectedOption: T | undefined;
  setSelectedOption: (option: T | undefined) => void;
}) {
  const handleOptionClick = (option: T) => {
    setSelectedOption(option);
  };

  return (
    <div className="flex rounded-lg overflow-hidden border border-foreground/70">
      {options.map((option, index) => (
        <button
          key={option}
          onClick={() => handleOptionClick(option)}
          className={`flex-1 py-2 px-4 text-center
            ${selectedOption === option ? "bg-primary text-white font-medium" : "bg-white text-foreground/70"}
            ${index !== 0 ? "border-l border-foreground/70" : ""}
          `}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
