import addCoursesVideoUrl from "@/assets/onboarding-adding-course.mp4";
import uploadingWorksheetVideoUrl from "@/assets/onboarding-uploading-worksheet.mp4";
import tutorVideoUrl from "@/assets/onboarding-tutor.mp4";
import onboardingExamsImgUrl from "@/assets/onboarding-exams.png";
import onboardingSupportImgUrl from "@/assets/onboarding-support.png";
export const tutorialIdMap = {
  dashboard: [
    <TutorialPage
      header="Welcome to the dashboard!"
      content="I'm Pensieve AI Tutor. I help you study and ace your courses. Let’s complete a short tutorial so you can get the most out of your experience!"
    />,
    <TutorialPage
      header="Add courses to your dashboard"
      content={
        <>
          Add your current courses to your dashboard. Can’t find your course? No
          worries - you can create your own!
          <video
            className="w-full rounded-lg"
            src={addCoursesVideoUrl}
            loop
            muted
            playsInline
            controls
          />
        </>
      }
    />,

    <TutorialPage
      header="Upload your worksheet"
      content={
        <>
          <p>
            Whether it's an assignment, a worksheet, or a past exam, you can
            upload any problem set in PDF or image formats. Our proprietary,
            AI-driven content pipeline will automatically process your upload so
            that I can optimize my tutoring for you!
          </p>
          <p>
            You can also explore worksheets uploaded by your peers in the{" "}
            <b>Community Worksheets</b> section.
          </p>
          <video
            className="w-full rounded-lg"
            src={uploadingWorksheetVideoUrl}
            loop
            muted
            playsInline
            controls
          />
        </>
      }
    />,

    <TutorialPage
      header="Study with our interactive worksheet and ask me any questions 🤓"
      content={
        <>
          <p>
            Once your content is processed, start solving your questions in our
            interactive format! Use our iPad app to write equations or your
            phone's camera to show your work. I will follow along to understand
            your thought process.
          </p>
          <p>
            If you get stuck you can ask me for help at any time. I’m fine tuned
            to help you in all your courses! No copy pasting necessary - I’ll
            keep track of your progress to offer live feedback.
          </p>
          <video
            className="w-full rounded-lg"
            src={tutorVideoUrl}
            loop
            muted
            playsInline
            controls
          />
        </>
      }
    />,

    <TutorialPage
      header="Access our exclusive test bank"
      content={
        <>
          <p>
            Looking for past exams to solve to prepare for your upcoming midterm
            or final? I got your back! We have over 500 past exams with official
            solutions that you can practice with me.
          </p>

          <img className="w-full rounded-lg" src={onboardingExamsImgUrl} />
        </>
      }
    />,
    <TutorialPage
      header="Let's get started!"
      content={
        <>
          <p>
            Need additional help? Click the <b>Support</b> icon in the top right
            corner, and we’ll get back to you ASAP.
          </p>

          <img className="rounded-lg" srcSet={onboardingSupportImgUrl} />
        </>
      }
    />,
  ],
};

function TutorialPage({
  header,
  content,
}: {
  header: string;
  content: React.ReactNode;
}) {
  return (
    <div className="flex flex-col items-start gap-4">
      <h1 className="font-heading text-2xl">{header}</h1>
      {content}
    </div>
  );
}

export type TutorialId = keyof typeof tutorialIdMap;

export const tutorialIds = Object.fromEntries(
  Object.keys(tutorialIdMap).map((id) => [id, id]),
) as Record<TutorialId, TutorialId>;
