import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { MemoryPNode } from "../p-node/memory";
import { usePNode } from "../p-node/p-node";

export type KeyboardState = "hidden" | "willShow" | "shown" | "willHide";

const keyboardStateNode = new MemoryPNode<KeyboardState>({
  path: [],
  rootData: "hidden",
});

export function useKeyboardState() {
  return usePNode(keyboardStateNode)[0];
}

export function initKeyboard() {
  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener("keyboardWillShow", () => {
      keyboardStateNode.value = "willShow";
    });

    Keyboard.addListener("keyboardWillHide", () => {
      keyboardStateNode.value = "willHide";
    });

    Keyboard.addListener("keyboardDidHide", () => {
      keyboardStateNode.value = "hidden";
      // Unfocus any active element when the keyboard hides
      document.activeElement instanceof HTMLElement &&
        document.activeElement.blur();
    });

    Keyboard.addListener("keyboardDidShow", () => {
      keyboardStateNode.value = "shown";

      setTimeout(() => {
        document.activeElement instanceof HTMLElement &&
          document.activeElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
      }, 200);
    });
  }
}
