import { useState } from "react";

export function useLocalStorage<T>(key: string, nullableFallback: T) {
  return useStorage(localStorage, key, nullableFallback);
}

export function useSessionStorage<T>(key: string, nullableFallback: T) {
  return useStorage(sessionStorage, key, nullableFallback);
}

function useStorage<T>(storage: Storage, key: string, nullableFallback: T) {
  const value = getStorageItem<T>(storage, key);

  const [_, trigger] = useState({});

  return [
    value ?? nullableFallback,
    (value: T) => {
      setStorageItem(storage, key, value);
      trigger({});
    },
  ] as const;
}

export function setLocalStorageItem<T>(key: string, value: T) {
  setStorageItem(localStorage, key, value);
}

export function setSessionStorageItem<T>(key: string, value: T) {
  setStorageItem(sessionStorage, key, value);
}

export function setStorageItem<T>(storage: Storage, key: string, value: T) {
  storage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageItem<T>(key: string): T | undefined {
  return getStorageItem(localStorage, key);
}

export function getSessionStorageItem<T>(key: string): T | undefined {
  return getStorageItem(sessionStorage, key);
}

export function getStorageItem<T>(
  storage: Storage,
  key: string,
): T | undefined {
  const item = storage.getItem(key);
  return item ? JSON.parse(item) : undefined;
}
