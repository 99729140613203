import { createFileRoute } from "@tanstack/react-router";
import { fbAuth } from "@/lib/foundation/firebase";
import { Button } from "@/lib/interface/button";
import { useTryPopup } from "@/lib/interface/dialog";
import { CenteredSpinner, Spinner } from "@/lib/interface/spinner";
import { TextArea, TextField } from "@/lib/interface/text-field";
import {
  createUser,
  getClaims,
  getUserIdFromEmail,
  putClaims,
} from "@/lib/meat/api";
import {
  Role,
  accessLevelRoleMap,
  roleAccessLevelMap,
  useIsRole,
} from "@/lib/meat/roles";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DropdownSelector from "@/lib/interface/dropdown-selector";
import { AppBarContainer } from "@/lib/meat/app-bar/app-bar";

export const Route = createFileRoute("/_auth/claims/")({
  component: () => (
    <AppBarContainer>
      <Index />
    </AppBarContainer>
  ),
});

function Index() {
  const isPensieve = useIsRole("pensieve");
  const queryClient = useQueryClient();

  const [targetEmail, setTargetEmail] = useState<string>(
    fbAuth.currentUser!.email!,
  );

  const {
    data: accessLevel,
    isFetching: accessLevelFetching,
    error: accessLevelError,
  } = useQuery(accessLevelQuery(targetEmail));

  const [searchText, setSearchText] = useState<string>(targetEmail);

  const tryPopup = useTryPopup();

  const tryPutAccessLevel = (accessLevel: number | null) =>
    tryPopup(async () => {
      await createUser(targetEmail.trim());
      const targetUserId = await getUserIdFromEmail(targetEmail.trim());
      await putClaims(
        targetUserId,
        accessLevel != null ? { access_level: accessLevel } : {},
      );
      refresh();
    });

  const refresh = () => {
    setTargetEmail(searchText);
    queryClient.invalidateQueries(accessLevelQuery(targetEmail));
  };

  if (isPensieve) {
    return (
      <div className="mx-auto my-4 rounded-lg px-5 pt-2 pb-3 w-[360px] bg-white shadow-lg">
        <div className="text-center text-lg font-bold mb-1">Claims</div>
        <div className="flex flex-row items-center gap-3 mb-2">
          <div className="ml-2">Email:</div>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onEnter={refresh}
            className="w-full"
          />
        </div>
        {accessLevelFetching ? (
          <Spinner className="mx-auto my-10 w-6 h-6" />
        ) : (
          <DropdownSelector
            placeholder="normal"
            placeholderSelectable
            values={Object.values(roleAccessLevelMap).toSorted((a, b) => a - b)}
            value={accessLevel}
            getKey={(value) => value}
            getLabel={(value) => accessLevelRoleMap[value] ?? "(Other)"}
            onChange={(value) => tryPutAccessLevel(value)}
          />
        )}
        {accessLevelError && (
          <div className="text-red-500 rotate-1 py-4">
            Error: {accessLevelError.message}
          </div>
        )}
        <div className="mt-1 flex flex-row flex-wrap justify-end items-center gap-3">
          <Button onClick={refresh}>Refresh</Button>
        </div>
      </div>
    );
  } else {
    return <CenteredSpinner />;
  }
}

function accessLevelQuery(email: string) {
  return {
    queryKey: ["accessLevel", email],
    queryFn: async () => {
      const targetUserId = await getUserIdFromEmail(email.trim());
      const claims = await getClaims(targetUserId);
      return (claims.access_level ?? 0) as number;
    },
    retry: false,
  } as const;
}
