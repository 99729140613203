import React, {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"; // Ensure React is in scope since we're using JSX.
import { cn } from "../foundation/misc";
import { handleCodeInput } from "../foundation/code-input";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { v4 } from "uuid";

export const textFieldLabelStyle = "font-medium text-sm pb-2 select-none";

export type TextFieldLabelProps = {
  required?: boolean;
  text: string;
  children: ReactNode;
};

export function TextFieldLabel({
  required,
  text,
  children,
}: TextFieldLabelProps) {
  return (
    <label className="flex flex-col">
      <span className={textFieldLabelStyle}>
        {text}
        {required && <span className="text-error">*</span>}
      </span>
      <div className="flex flex-col gap-2">{children}</div>
    </label>
  );
}

export function TextFieldFakeLabel({
  required,
  text,
  children,
}: TextFieldLabelProps) {
  return (
    <div className="flex flex-col">
      <span className={textFieldLabelStyle}>
        {text}
        {required && <span className="text-error">*</span>}
      </span>
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  );
}

export function TextArea({
  className,
  dynamic,
  value,
  big,
  gray,
  solid,
  icon,
  onEnter,
  minRows,
  codeInput,
  setFocused,
  focusRef,
  onEmptyBackspace,
  stopArrowPropagation,
  stopSpacePropagation,
  ...others
}: {
  className?: string;
  dynamic?: boolean;
  value: string;
  big?: boolean;
  solid?: boolean;
  icon?: React.ReactNode;
  gray?: boolean;
  onEnter?: () => void;
  minRows?: number;
  codeInput?: boolean;
  setFocused?: (focused: boolean) => void;
  focusRef?: React.MutableRefObject<(() => void) | null>;
  onEmptyBackspace?: () => void;
  stopSpacePropagation?: boolean;
  stopArrowPropagation?: boolean;
} & TextareaAutosizeProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  if (focusRef) {
    focusRef.current = () => {
      textAreaRef.current?.focus();
      textAreaRef.current?.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length,
      );
    };
  }

  useEffect(() => {
    const onFocus = () => {
      setFocused?.(true);
    };
    const onBlur = () => {
      setFocused?.(false);
    };
    const textArea = textAreaRef.current as HTMLTextAreaElement;
    textArea.addEventListener("focus", onFocus);
    textArea.addEventListener("blur", onBlur);
    return () => {
      textArea.removeEventListener("focus", onFocus);
      textArea.removeEventListener("blur", onBlur);
    };
  }, [setFocused]);

  const id = useMemo(() => v4(), []);
  const [fontWeight, setFontWeight] = useState<string>();
  const [fontSize, setFontSize] = useState<string>();
  const [fontFamily, setFontFamily] = useState<string>();
  const font =
    fontWeight &&
    fontSize &&
    fontFamily &&
    `${fontWeight} ${fontSize} ${fontFamily}`;
  useEffect(() => {
    if (dynamic) {
      const inputElement = document.getElementById(id);
      if (inputElement !== null) {
        const style = window.getComputedStyle(inputElement);
        setFontWeight(style.fontWeight);
        setFontSize(style.fontSize);
        setFontFamily(style.fontFamily);
      }
    }
  }, [dynamic, id]);
  const dynamicWidth = useMemo(
    () =>
      dynamic
        ? font
          ? `${measureText(value, font)}px`
          : `calc(${Math.max(getLongestLineSize(value), 7)}ch + 1rem)`
        : "100%",
    [dynamic, font, value],
  );
  const textArea = (
    <TextareaAutosize
      id={id}
      style={{
        width: dynamicWidth,
      }}
      ref={textAreaRef}
      minRows={minRows}
      className={cn(
        "resize-none px-4 leading-[22px] border-border border-solid border  bg-transparent rounded-lg overflow-y-hidden grow",
        dynamic && "whitespace-pre box-content",
        gray && "border-foreground border-opacity-20",
        big ? "py-[14px]" : "py-[10px]",
        solid && "border-background bg-background",
        !icon &&
          "focus-within:outline-primary focus-within:outline focus-within:outline-2 focus-within:ring-0",
        !icon && className,
        !!icon && "outline-0 outline-none",
      )}
      onKeyDown={(e) => {
        if (stopSpacePropagation && e.key === " ") {
          e.stopPropagation();
        }
        if (
          stopArrowPropagation &&
          ["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"].includes(e.key)
        ) {
          e.stopPropagation();
        }
        if (onEnter != null && e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          onEnter();
        } else if (
          onEmptyBackspace != null &&
          e.key === "Backspace" &&
          !value
        ) {
          e.preventDefault();
          onEmptyBackspace();
        } else if (codeInput) {
          handleCodeInput(e, () => {
            others.onChange?.(e as any);
          });
        }
      }}
      value={value}
      {...others}
    />
  );

  if (icon) {
    return (
      <div
        className={cn(
          "flex flex-row items-end bg-background rounded-lg focus-within:outline-primary focus-within:outline focus-within:outline-2 focus-within:ring-0",
          className,
        )}
      >
        {textArea}
        <div className="mr-[12px] mb-[5px]">{icon}</div>
      </div>
    );
  }

  return textArea;
}

function getLongestLine(text: string) {
  const lines = text.split("\n");
  return lines.reduce((longest, line) =>
    line.length > longest.length ? line : longest,
  );
}

function getLongestLineSize(text: string) {
  const lines = text.split("\n");
  return lines.reduce((longest, line) => Math.max(longest, line.length), 0);
}

export function TextField({
  className,
  style,
  onEnter,
  dynamic,
  icon,
  big,
  gray,
  value,
  placeholder,
  initiallySelected,
  ...others
}: {
  label?: string;
  className?: string;
  onEnter?: (e: React.KeyboardEvent) => void;
  dynamic?: boolean;
  mono?: boolean;
  icon?: React.ReactNode;
  big?: boolean;
  gray?: boolean;
  solid?: boolean;
  initiallySelected?: boolean;
  value: string;
} & React.InputHTMLAttributes<HTMLInputElement>) {
  const id = useMemo(() => v4(), []);
  const size = dynamic ? undefined : 5;
  const [fontWeight, setFontWeight] = useState<string>();
  const [fontSize, setFontSize] = useState<string>();
  const [fontFamily, setFontFamily] = useState<string>();
  const font =
    fontWeight &&
    fontSize &&
    fontFamily &&
    `${fontWeight} ${fontSize} ${fontFamily}`;
  useLayoutEffect(() => {
    if (dynamic) {
      const inputElement = document.getElementById(id);
      if (inputElement !== null) {
        const style = window.getComputedStyle(inputElement);
        setFontWeight(style.fontWeight);
        setFontSize(style.fontSize);
        setFontFamily(style.fontFamily);
      }
    }
  }, [dynamic, id]);
  useEffect(() => {
    const inputElement = document.getElementById(id) as HTMLInputElement;
    if (initiallySelected) {
      inputElement.select();
    }
  }, [id, initiallySelected]);
  const input = (
    <input
      id={id}
      type="text"
      onChange={others.onChange}
      onKeyDown={
        onEnter != null
          ? (e) => {
              if (e.key === "Enter") {
                onEnter(e);
              }
            }
          : undefined
      }
      className={cn(
        "px-[19px] leading-[22px] border-border border-solid border bg-transparent flex-1 outline-primary rounded-lg",
        big ? "py-[14px]" : "py-[10px]",
        gray && "border-foreground border-opacity-20",
        !!icon && "border-0 focus:outline-0 outline-none",
        !icon && className,
        dynamic && "box-content",
      )}
      style={{
        ...style,
        outline: icon ? "none" : undefined,
        width: font && measureText(value || placeholder || "", font),
      }}
      size={size}
      placeholder={placeholder}
      value={value}
      {...others}
    />
  );

  if (icon) {
    return (
      <div
        className={cn(
          "flex flex-row items-center border-border border-solid border rounded-lg focus-within:outline-primary focus-within:outline focus-within:outline-2 focus-within:ring-0",
          className,
        )}
      >
        {input}
        <div className="mr-[12px]">{icon}</div>
      </div>
    );
  }

  return input;
}

let measureTextCanvas: HTMLCanvasElement | undefined = undefined;

function measureText(text: string, font: string): number {
  const lines = text.split("\n");
  let result = 0;
  for (const line of lines) {
    result = Math.max(result, measureLine(line, font));
  }
  return result;
}

function measureLine(text: string, font: string): number {
  const canvas =
    measureTextCanvas || (measureTextCanvas = document.createElement("canvas"));
  const context = canvas.getContext("2d")!;
  context.font = font;
  return context.measureText(text).width + 1;
}
