import { titleCase } from "title-case";

export type AcademicSeason = "spring" | "summer" | "fall" | "winter";
export type ExamTypeName = "midterm" | "final" | "other";

/**
 * valueOf is comparable
 */
export class AcademicTerm {
  constructor(
    public readonly year: number,
    public readonly season: AcademicSeason
  ) {
    this.year = year;
    this.season = season;
  }

  valueOf() {
    return this.year * 10 + academicSeasonToNumber(this.season);
  }

  toString() {
    return `${titleCase(this.season)} ${this.year}`;
  }

  static tryParse(str: string) {
    try {
      return this.parse(str);
    } catch (e) {
      return null;
    }
  }

  static parse(str: string) {
    const [season, year] = str.trim().split(" ");

    const parsedSeason = parseAcademicSeason(season);
    const parsedYear = parseInt(year, 10);

    if (isNaN(parsedYear)) {
      throw new Error("Invalid year");
    }

    return new AcademicTerm(parsedYear, parsedSeason);
  }
}

export class ExamType {
  constructor(
    public readonly typeName: ExamTypeName,
    public readonly typeNumber: number | null
  ) {}

  valueOf() {
    return examTypeNameToNumber(this.typeName) * 10 + (this.typeNumber ?? 0);
  }

  toString() {
    return `${titleCase(this.typeName)} ${this.typeNumber ?? ""}`.trim();
  }

  static tryParse(str: string) {
    try {
      return this.parse(str);
    } catch (e) {
      return null;
    }
  }

  static parse(str: string) {
    const split = str.trim().split(" ");
    const typeNameIndex = split.findIndex(
      (s) =>
        s.trim().toLowerCase() === "midterm" ||
        s.trim().toLowerCase() === "final"
    );

    const lastNumber = split.findLast((s) => !isNaN(parseInt(s, 10))) ?? "";

    if (typeNameIndex === -1) {
      return new ExamType(
        "other",
        lastNumber ? parseInt(lastNumber, 10) : null
      );
    }

    const typeName = split[typeNameIndex].trim().toLowerCase();
    const typeNumber = parseInt(split[typeNameIndex + 1] ?? "", 10);

    if (isNaN(typeNumber)) {
      return new ExamType(parseExamTypeName(typeName), null);
    }

    return new ExamType(parseExamTypeName(typeName), typeNumber);
  }
}

export class ExamSpecifier {
  constructor(
    public readonly academicTerm: AcademicTerm,
    public readonly examType: ExamType
  ) {}

  valueOf() {
    return this.academicTerm.valueOf() * 1000 + this.examType.valueOf();
  }

  toString() {
    return `${this.academicTerm} ${this.examType}`;
  }

  static tryParse(str: string) {
    try {
      return this.parse(str);
    } catch (e) {
      return null;
    }
  }

  static parse(str: string) {
    const split = str.split(" ");
    if (split.length < 3) {
      throw new Error("Invalid exam specifier");
    }

    const academicTerm = AcademicTerm.parse(split.slice(0, 2).join(" "));
    const examType = ExamType.parse(split.slice(2).join(" "));

    return new ExamSpecifier(academicTerm, examType);
  }
}

function parseExamTypeName(str: string) {
  const lowerCaseStr = str.trim().toLowerCase();
  if (
    lowerCaseStr === "midterm" ||
    lowerCaseStr === "final" ||
    lowerCaseStr === "other"
  ) {
    return lowerCaseStr;
  }
  throw new Error("Invalid exam type name");
}

function examTypeNameToNumber(examTypeName: ExamTypeName) {
  switch (examTypeName) {
    case "other":
      return 0;
    case "midterm":
      return 1;
    case "final":
      return 2;
  }
}

function parseAcademicSeason(season: string): AcademicSeason {
  const lowerCaseSeason = season.trim().toLowerCase();

  switch (lowerCaseSeason) {
    case "winter":
      return "winter";
    case "spring":
      return "spring";
    case "summer":
      return "summer";
    case "fall":
    case "autumn":
      return "fall";
    default:
      throw new Error("Invalid season");
  }
}

function academicSeasonToNumber(season: AcademicSeason) {
  switch (season) {
    case "winter":
      return 1;
    case "spring":
      return 2;
    case "summer":
      return 3;
    case "fall":
      return 4;
    default:
      throw new Error("Invalid season");
  }
}
