import { registerPlugin } from "@capacitor/core";

interface PencilPlugin {
  addListener(
    eventName: "pencilDoubleTapped",
    listener: () => void,
  ): Promise<void>;
}

const PencilPlugin = registerPlugin<PencilPlugin>("Pencil");

export default PencilPlugin;
