import { useMatches, getRouterContext, Outlet } from "@tanstack/react-router";
import {
  AnimatePresence,
  useIsPresent,
  useDragControls,
  motion,
} from "framer-motion";
import { cloneDeep } from "lodash";
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { cn } from "../foundation/misc";

export type AnimatedOutletTransitionType = "backward" | "forward";

const SetAnimatedOutletTransitionContext = createContext<
  (type: AnimatedOutletTransitionType) => void
>(() => {});

export function useSetAnimatedOutletTransitionType() {
  return useContext(SetAnimatedOutletTransitionContext);
}

export function AnimatedOutlet() {
  const [transitionType, setTransitionType] =
    useState<AnimatedOutletTransitionType>("forward");

  const matches = useMatches();
  const lastId = matches[matches.length - 1].id;

  return (
    <SetAnimatedOutletTransitionContext.Provider value={setTransitionType}>
      <AnimatePresence
        mode="popLayout"
        onExitComplete={() => {
          setTransitionType("forward");
        }}
      >
        <InternalAnimatedOutlet key={lastId} transitionType={transitionType} />
      </AnimatePresence>
    </SetAnimatedOutletTransitionContext.Provider>
  );
}

export const InternalAnimatedOutlet = forwardRef<
  HTMLDivElement,
  { transitionType: AnimatedOutletTransitionType }
>(({ transitionType }, ref) => {
  const constraintRef = useRef<HTMLDivElement>(null);
  const RouterContext = getRouterContext();

  const routerContext = useContext(RouterContext);

  const renderedContext = useRef(routerContext);

  const isPresent = useIsPresent();

  const dragControls = useDragControls();

  if (isPresent) {
    renderedContext.current = cloneDeep(routerContext);
  }

  const setTransitionType = useSetAnimatedOutletTransitionType();
  const [aboutToGoBack, setAboutToGoBack] = useState(false);
  const [goingBack, setGoingBack] = useState(false);

  useEffect(() => {
    if (goingBack) {
      history.back();
      setGoingBack(false);
      setAboutToGoBack(false);
    }
  }, [goingBack]);

  console;

  return (
    <div
      className={cn(
        "flex-col w-full h-full relative",
        !isPresent && transitionType === "backward" && "z-50",
      )}
      ref={ref}
    >
      <div
        className={cn(
          "absolute h-full top-0 left-0",
          aboutToGoBack ? "w-[1000px]" : "w-full",
        )}
        ref={constraintRef}
      />
      <div className="absolute w-5 left-0 h-full z-10 pt-32">
        <div
          onPointerDown={(e) => dragControls.start(e)}
          className="w-full h-full"
        ></div>
      </div>
      <motion.div
        className="flex flex-col w-full h-full bg-background overflow-hidden"
        exit={{
          x: transitionType === "forward" ? -1 : document.body.clientWidth,
        }}
        initial={{
          x: transitionType === "forward" ? document.body.clientWidth : 0,
        }}
        animate={{
          x: transitionType === "forward" ? 0 : undefined,
        }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        drag="x"
        dragElastic={1}
        dragConstraints={{ left: 0, right: 0 }}
        dragListener={false}
        dragControls={dragControls}
        onDrag={(e, info) => {
          if (info.offset.x > 100) {
            setAboutToGoBack(true);
          } else {
            setAboutToGoBack(false);
          }
        }}
        onDragTransitionEnd={() => {
          if (isPresent) {
            setTransitionType("forward");
          }
        }}
        onDragEnd={(e, info) => {
          if (aboutToGoBack) {
            setTransitionType("backward");
            setGoingBack(true);
          }
        }}
        onAnimationComplete={() => {
          if (transitionType === "backward") {
            setTransitionType("forward");
          }
        }}
      >
        <RouterContext.Provider value={renderedContext.current}>
          <Outlet />
        </RouterContext.Provider>
      </motion.div>
    </div>
  );
});
