import { useFbValue } from "../foundation/p-node/firebase";

const defaultFeatureFlags = {
  image_fill_in_the_blank: false,
  input_device: false,
  markdown_fill_in_the_blank: false,
  my_worksheets: false,
  popup_feedback: false,
  unofficial_courses: false,
  unofficial_worksheet_deleting: false,
  user_linked_schools: false,
  solutions_in_upload: false,
  dashboard_tutorial: false,
  markdown_field_2: false,
  social_sign_ins: false,
  voice_tutor: false,
};

export type FbFeatureFlagsObject = typeof defaultFeatureFlags;

export function useFeatureFlags(): FbFeatureFlagsObject {
  const [featureFlags] =
    useFbValue<Partial<FbFeatureFlagsObject>>("/feature_flags");
  return {
    ...defaultFeatureFlags,
    ...featureFlags,
  };
}
