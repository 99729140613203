import { useCallback, useMemo, useRef, useSyncExternalStore } from "react";
import { deepEqual } from "fast-equals";
import { Listenable } from "../listenable";

export type PNodeKeyType<T> = keyof NonNullable<T>;

export type PNodeChildType<T, K extends PNodeKeyType<T>> =
  T extends NonNullable<unknown> ? T[K] : NonNullable<T>[K] | undefined;

export interface PNode<T> {
  child<K extends PNodeKeyType<T>>(key: K): PNode<PNodeChildType<T, K>>;
  transact(update: (value: T) => T): T | Promise<T>;
  value: T;
  readonly listenable: Listenable;
}

export function usePNode<T>(
  pNode: PNode<T>,
  options: {
    nullableFallback: NonNullable<T>;
    reviver?: undefined;
    replacer?: undefined;
  },
): [NonNullable<T>, (value: T) => void, () => void];

export function usePNode<T>(
  pNode: PNode<T>,
  options?: {
    nullableFallback?: T;
    reviver?: undefined;
    replacer?: undefined;
  },
): [T, (value: T) => void, () => void];

export function usePNode<T, U>(
  pNode: PNode<T>,
  options: {
    nullableFallback: NonNullable<T>;
    reviver: (replaced: NonNullable<T>) => U;
    replacer: (revived: U) => T;
  },
): [U, (value: U) => void, () => void];

export function usePNode<T, U>(
  pNode: PNode<T>,
  options: {
    nullableFallback: NonNullable<T>;
    reviver: (replaced: NonNullable<T>) => U;
    replacer?: undefined;
  },
): [U, undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T>,
  options: {
    nullableFallback: NonNullable<T>;
    reviver: (replaced: NonNullable<T>) => U;
    replacer?: (revived: U) => T;
  },
): [U, ((value: U) => void) | undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T>,
  options: {
    nullableFallback?: T;
    reviver: (replaced: T) => U;
    replacer: (revived: U) => T;
  },
): [U, (value: U) => void, () => void];

export function usePNode<T, U>(
  pNode: PNode<T>,
  options: {
    nullableFallback?: T;
    reviver: (replaced: T) => U;
    replacer?: undefined;
  },
): [U, undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T>,
  options: {
    nullableFallback?: T;
    reviver: (replaced: T) => U;
    replacer?: (revived: U) => T;
  },
): [U, ((value: U) => void) | undefined, () => void];

export function usePNode<T>(
  pNode: PNode<T> | null | undefined,
  options?: {
    nullableFallback?: undefined;
    reviver?: undefined;
    replacer?: undefined;
  },
): [T | undefined, (value: T) => void, () => void];

export function usePNode<T>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: NonNullable<T>;
    reviver?: undefined;
    replacer?: undefined;
  },
): [NonNullable<T>, (value: T) => void, () => void];

export function usePNode<T>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: T;
    reviver?: undefined;
    replacer?: undefined;
  },
): [T, (value: T) => void, () => void];

export function usePNode<T>(
  pNode: PNode<T> | null | undefined,
  options?: {
    nullableFallback?: T;
    reviver?: undefined;
    replacer?: undefined;
  },
): [T | undefined, (value: T) => void, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback?: undefined;
    reviver: (replaced: T | undefined) => U;
    replacer: (revived: U) => T;
  },
): [U, (value: U) => void, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback?: undefined;
    reviver: (replaced: T | undefined) => U;
    replacer?: undefined;
  },
): [U, undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback?: undefined;
    reviver: (replaced: T | undefined) => U;
    replacer?: (revived: U) => T;
  },
): [U, ((value: U) => void) | undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: NonNullable<T>;
    reviver: (replaced: NonNullable<T>) => U;
    replacer: (revived: U) => T;
  },
): [U, (value: U) => void, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: NonNullable<T>;
    reviver: (replaced: NonNullable<T>) => U;
    replacer?: undefined;
  },
): [U, undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: NonNullable<T>;
    reviver: (replaced: NonNullable<T>) => U;
    replacer?: undefined;
  },
): [U, ((revived: U) => T) | undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: T;
    reviver: (replaced: T) => U;
    replacer: (revived: U) => T;
  },
): [U, (value: U) => void, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: T;
    reviver: (replaced: T) => U;
    replacer?: undefined;
  },
): [U, undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options: {
    nullableFallback: T;
    reviver: (replaced: T) => U;
    replacer?: (revived: U) => T;
  },
): [U, ((value: U) => void) | undefined, () => void];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options?:
    | {
        nullableFallback?: T;
        reviver?: undefined;
        replacer?: undefined;
      }
    | {
        nullableFallback?: undefined;
        reviver: (replaced: T | undefined) => U;
        replacer?: (revived: U) => T;
      }
    | {
        nullableFallback: T;
        reviver: (replaced: T) => U;
        replacer?: (revived: U) => T;
      },
): [
  T | U | undefined,
  ((value: T) => void) | ((value: U) => void) | undefined,
  () => void,
];

export function usePNode<T, U>(
  pNode: PNode<T> | null | undefined,
  options?:
    | {
        nullableFallback?: T;
        reviver?: undefined;
        replacer?: undefined;
      }
    | {
        nullableFallback?: undefined;
        reviver: (replaced: T | undefined) => U;
        replacer?: (revived: U) => T;
      }
    | {
        nullableFallback: T;
        reviver: (replaced: T) => U;
        replacer?: (revived: U) => T;
      },
): [
  T | U | undefined, // value
  ((value: T) => void) | ((value: U) => void) | undefined, // setValue
  () => void, // refetchValue
] {
  const onStoreChange = useRef<() => void>();

  const subscribe = useCallback(
    (callback: () => void) => {
      onStoreChange.current = callback;
      const unsubscribe = pNode?.listenable.subscribe(callback);
      return () => {
        unsubscribe?.();
        onStoreChange.current = undefined;
      };
    },
    [pNode],
  );

  const value = useRef<T | U>();

  const getSnapshot = useCallback(() => {
    let x: T | U | undefined = pNode?.value;
    if (options !== undefined) {
      if (options.nullableFallback !== undefined) {
        x = x ?? options.nullableFallback;
        if (options.reviver !== undefined) {
          x = options.reviver(x);
        }
      } else {
        if (options.reviver !== undefined) {
          x = (options.reviver as (_: T | undefined) => U)(x);
        }
      }
    }
    if (!deepEqual(value.current, x)) {
      value.current = x;
    }
    return value.current;
  }, [pNode, options]);

  const snapshot = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  return [
    snapshot,
    useMemo(
      () =>
        options?.replacer !== undefined
          ? (value: U) => {
              pNode!.value = options.replacer!(value);
            }
          : (value: T) => {
              pNode!.value = value;
            },
      [options?.replacer, pNode],
    ),
    () => onStoreChange.current?.(),
  ];
}
