import { getFbUserPromise } from "@/lib/foundation/firebase";
import { isValidOnboardingData } from "@/lib/meat/onboarding";
import { Capacitor } from "@capacitor/core";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  beforeLoad: async ({
    search,
  }: {
    search: { ttclid?: string; redirect?: string; source?: string };
  }) => {
    if (search.ttclid) {
      sessionStorage.setItem("ttclid", search.ttclid);
    }

    const sessionTtclid = sessionStorage.getItem("ttclid");

    if (sessionTtclid && !search.ttclid) {
      throw redirect({
        search: {
          ...search,
          ttclid: sessionTtclid,
        },
      });
    }

    const user = await getFbUserPromise();

    if (user) {
      if (search?.redirect) {
        throw redirect({
          to: search.redirect,
        });
      } else {
        throw redirect({
          to: "/dashboard",
        });
      }
    }

    if (Capacitor.isNativePlatform()) {
      throw redirect({ to: "/onboarding", search: { page: undefined } });
    }
  },
});
