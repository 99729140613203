import { AppBarCustomContentProvider } from "@/lib/meat/app-bar/app-bar";
import { createRootRoute, Outlet } from "@tanstack/react-router";
import { Suspense, lazy, useEffect, useState } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ShowDialogWrapper } from "@/lib/interface/dialog";
import { ShowContextMenuContextProvider } from "@/lib/interface/context-menu/components";
import { IntercomProvider } from "@/lib/interface/intercom";
import { DraftFormDataProvider } from "@/lib/meat/upload-form";
import { TutorialPopupProvider } from "@/lib/interface/tutorial";
import { tutorialIdMap } from "@/lib/meat/tutorials";
import { TooltipProvider } from "@/lib/interface/tooltips";
import { Capacitor } from "@capacitor/core";
import { Helmet } from "react-helmet";
import { AppBarTabletToolProvider } from "@/lib/meat/app-bar/tablet-tools";
import { AnimatedOutlet } from "@/lib/interface/animated-outlet";
import { ImpersonateUserProvider } from "@/lib/meat/impersonate";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

const queryClient = new QueryClient();

export const Route = createRootRoute({
  beforeLoad: () => {
    return {
      queryClient,
      customAppBar: false,
    };
  },
  component: () => <RootComponent />,
});

function RootComponent() {
  const [zoom, setZoom] = useState(1.0);

  return (
    <main className="flex flex-col bg-background relative overflow-hidden">
      {Capacitor.isNativePlatform() && (
        <Helmet>
          <meta
            name="viewport"
            content={`initial-scale=${zoom}, viewport-fit=cover, user-scalable=no`}
          />
        </Helmet>
      )}
      <AppBarCustomContentProvider>
        <QueryClientProvider client={queryClient}>
          <ImpersonateUserProvider>
            <AppBarTabletToolProvider>
              <IntercomProvider>
                <DraftFormDataProvider>
                  <ShowContextMenuContextProvider>
                    <ShowDialogWrapper>
                      <TutorialPopupProvider tutorialIdMap={tutorialIdMap}>
                        <TooltipProvider>
                          {Capacitor.isNativePlatform() ? (
                            <AnimatedOutlet />
                          ) : (
                            <Outlet />
                          )}
                          <ReactQueryDevtools />
                          <Suspense>
                            <TanStackRouterDevtools />
                          </Suspense>
                        </TooltipProvider>
                      </TutorialPopupProvider>
                    </ShowDialogWrapper>
                  </ShowContextMenuContextProvider>
                </DraftFormDataProvider>
              </IntercomProvider>
            </AppBarTabletToolProvider>
          </ImpersonateUserProvider>
        </QueryClientProvider>
      </AppBarCustomContentProvider>
    </main>
  );
}
