// Import necessary dependencies
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  ReactNode,
} from "react";
import ReactDOM from "react-dom";
import VisibilitySensor from "react-visibility-sensor";
import { MemoryPNode, useMemoryPNode } from "../foundation/p-node/memory";
import { useFbPNode } from "../foundation/p-node/firebase";
import { usePNode } from "../foundation/p-node/p-node";
import { useFbUser } from "../foundation/firebase";
import { cn } from "../foundation/misc";

// Tooltip Data Interface
interface TooltipData {
  id: string;
  tooltip: React.ReactNode;
  element: HTMLElement;
  container: HTMLElement | null;
}

// Tooltip Context Type
interface TooltipContextType {
  addTooltip: (
    id: string,
    tooltip: React.ReactNode,
    element: HTMLElement,
    container: HTMLElement | null,
  ) => void;
  removeTooltip: (id: string, element: HTMLElement) => void;
  currentTooltipData: TooltipData | null;
  clearCurrentTooltip: () => void;
}

// Create Tooltip Context
const TooltipContext = createContext<TooltipContextType | undefined>(undefined);

// TooltipProvider: Provides context for tooltips
export function TooltipProvider({ children }: { children: React.ReactNode }) {
  const [visibleTooltips, setVisibleTooltips] = useState<
    Map<string, TooltipData[]>
  >(new Map());
  const [currentTooltipData, setCurrentTooltipData] =
    useState<TooltipData | null>(null);

  const addTooltip = (
    id: string,
    tooltip: React.ReactNode,
    element: HTMLElement,
    container: HTMLElement | null,
  ) => {
    setVisibleTooltips((prev) => {
      const newMap = new Map(prev);
      const tooltipArray = newMap.get(id) || [];
      tooltipArray.push({ id, tooltip, element, container });
      newMap.set(id, tooltipArray);
      return newMap;
    });
  };

  const removeTooltip = (id: string, element: HTMLElement) => {
    setVisibleTooltips((prev) => {
      const newMap = new Map(prev);
      const tooltipArray = newMap.get(id);
      if (tooltipArray) {
        const filteredArray = tooltipArray.filter(
          (td) => td.element !== element,
        );
        if (filteredArray.length > 0) {
          newMap.set(id, filteredArray);
        } else {
          newMap.delete(id);
        }
      }
      return newMap;
    });
  };

  const clearCurrentTooltip = () => {
    setCurrentTooltipData(null);
  };

  const dismissalsNode = useTooltipDismissalsNode();

  const displayNextTooltipRef = useRef(() => {});
  displayNextTooltipRef.current = () => {
    if (currentTooltipData != null) return; // Tooltip already being shown

    const tooltipsArray = Array.from(visibleTooltips.values()).flat();

    if (tooltipsArray.length === 0) return;

    // Filter out dismissed tooltips
    const undisregardedTooltips = tooltipsArray.filter((t) => {
      return dismissalsNode.child(t.id).value !== true;
    });

    if (undisregardedTooltips.length === 0) return;

    // Sort tooltips by DOM order
    undisregardedTooltips.sort((a, b) => {
      if (
        a.element.compareDocumentPosition(b.element) &
        Node.DOCUMENT_POSITION_FOLLOWING
      ) {
        return -1;
      } else {
        return 1;
      }
    });

    const firstTooltip = undisregardedTooltips[0];
    setCurrentTooltipData(firstTooltip);
  };

  useEffect(() => {
    displayNextTooltipRef.current();
    const interval = setInterval(() => {
      setVisibleTooltips((prev) => {
        const newMap = new Map<string, TooltipData[]>();
        prev.forEach((tooltips, id) => {
          const filteredTooltips = tooltips.filter((tooltip) =>
            document.body.contains(tooltip.element),
          );
          if (filteredTooltips.length > 0) {
            newMap.set(id, filteredTooltips);
          }
        });
        return newMap;
      });

      if (
        currentTooltipData != null &&
        !document.body.contains(currentTooltipData.element)
      ) {
        clearCurrentTooltip();
      }

      displayNextTooltipRef.current();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [visibleTooltips, currentTooltipData]);

  return (
    <TooltipContext.Provider
      value={{
        addTooltip,
        removeTooltip,
        currentTooltipData,
        clearCurrentTooltip,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
}

interface TooltipContainerContextType {
  container: HTMLElement | null;
  sizeChangeCount: number;
}

export const TooltipContainerContext =
  createContext<TooltipContainerContextType>({
    container: null,
    sizeChangeCount: 0,
  });

// TooltipContainerProvider: Provides a stacking context for absolutely positioned tooltips
export function TooltipContainerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [sizeChangeCount, setSizeChangeCount] = useState(0);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      // Increment the count to trigger re-renders of TooltipRenderer
      setSizeChangeCount((prevCount) => prevCount + 1);
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <TooltipContainerContext.Provider
      value={{ container: containerRef.current, sizeChangeCount }}
    >
      <div ref={containerRef} className="relative overflow-visible">
        {children}
      </div>
    </TooltipContainerContext.Provider>
  );
}
// TooltipTarget: Wraps a component to provide a tooltip
interface TooltipTargetProps {
  className?: string;
  children: React.ReactNode;
  tooltip: React.ReactNode;
  tooltipId: string;
}

export function TooltipTarget({
  className,
  children,
  tooltip,
  tooltipId,
}: TooltipTargetProps) {
  const tooltipContext = useContext(TooltipContext);
  const { container: tooltipContainer } = useContext(TooltipContainerContext);
  const targetRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  if (!tooltipContext) {
    throw new Error("TooltipTarget must be used within a TooltipProvider");
  }

  const { addTooltip, removeTooltip, currentTooltipData } = tooltipContext;
  const dismissalsNode = useTooltipDismissalsNode();
  const [dismissed] = usePNode(dismissalsNode.child(tooltipId));
  useEffect(() => {
    if (isVisible && !dismissed && targetRef.current) {
      addTooltip(tooltipId, tooltip, targetRef.current, tooltipContainer);
    } else if (targetRef.current) {
      removeTooltip(tooltipId, targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        removeTooltip(tooltipId, targetRef.current);
      }
    };
  }, [isVisible, tooltipId, isVisible]);

  const showTooltip =
    currentTooltipData?.id === tooltipId &&
    currentTooltipData?.element === targetRef.current;

  return (
    <VisibilitySensor onChange={setIsVisible}>
      <div ref={targetRef} className={className}>
        {children}
        {showTooltip &&
          tooltipContainer &&
          targetRef.current &&
          ReactDOM.createPortal(
            <TooltipRenderer
              tooltip={tooltip}
              targetElement={targetRef.current}
              tooltipId={tooltipId}
            />,
            tooltipContainer,
          )}
      </div>
    </VisibilitySensor>
  );
}
function TooltipRenderer({
  tooltip,
  targetElement,
  tooltipId,
}: {
  tooltip: React.ReactNode;
  targetElement: HTMLElement;
  tooltipId: string;
}) {
  const [position, setPosition] = useState<{
    top: number;
    left?: number;
    right?: number;
    renderOnRight: boolean;
  }>({
    top: 0,
    renderOnRight: true,
  });

  const { clearCurrentTooltip } = useContext(TooltipContext)!;
  const { container: containerElement, sizeChangeCount } = useContext(
    TooltipContainerContext,
  );
  const maxTooltipWidth = 300; // Maximum width of the tooltip
  const dismissalsNode = useTooltipDismissalsNode();

  useEffect(() => {
    if (!containerElement) return;

    const targetRect = targetElement.getBoundingClientRect();
    const containerRect = containerElement.getBoundingClientRect();

    const screenPadding = 20; // Padding from the edge of the screen

    // Calculate available space on both sides
    const spaceRight = window.innerWidth - targetRect.right - screenPadding;
    const spaceLeft = targetRect.left - screenPadding;

    // Determine if we have more space on the left or right
    const renderOnRight = spaceRight >= spaceLeft;

    // Calculate vertical center position relative to the container
    const top = targetRect.top - containerRect.top + targetRect.height / 2;

    // Set position based on render side
    let positionStyle: { left?: number; right?: number };
    if (renderOnRight) {
      const left = targetRect.right - containerRect.left + 16; // Adjust for margin
      positionStyle = { left };
    } else {
      const right = containerRect.right - targetRect.left + 16; // Adjust for margin
      positionStyle = { right };
    }

    setPosition({ top, ...positionStyle, renderOnRight });
  }, [targetElement, containerElement, sizeChangeCount]);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dismissalsNode.child(tooltipId).value = true;
    clearCurrentTooltip();
  };

  if (!containerElement) return null;

  return (
    <div
      className="absolute bg-white border border-foreground/40 rounded p-4 px-6 shadow z-10"
      style={{
        top: position.top,
        ...(position.left !== undefined
          ? { left: position.left }
          : { right: position.right }),
        maxWidth: `${maxTooltipWidth}px`, // Set maximum width
        width: "max-content", // Dynamic width based on content
        transform: "translateY(-50%)", // Center vertically
      }}
    >
      <button className="p-0.5 absolute top-0 right-0" onClick={handleClose}>
        <span className="material-symbols-outlined text-base">close</span>
      </button>
      {tooltip}
      {/* Triangle pointing to the target */}
      <div
        className={cn(
          "absolute top-1/2 -left-2 transform -translate-y-1/2 w-4 h-4 bg-white border-t border-l border-foreground/40 -rotate-45",
          !position.renderOnRight && "left-auto -right-2 rotate-[135deg]",
        )}
      ></div>
    </div>
  );
}
function useTooltipDismissalsNode() {
  const user = useFbUser();

  const memoryNode = useMemoryPNode<Record<string, boolean>>({});

  const firebaseNode = useFbPNode<Record<string, boolean> | undefined>(
    user?.uid ? `interface/tooltip_dismissals/${user?.uid}` : undefined,
  );

  if (user) {
    return firebaseNode!;
  } else {
    return memoryNode;
  }
}
