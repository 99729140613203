import { ampl } from "@/lib/foundation/amplitude";
import {
  fbDbRef,
  getFbUserPromise,
  useFbUser,
} from "@/lib/foundation/firebase";
import { getLocalStorageItem } from "@/lib/foundation/local-storage";
import { fbPNodeQueryOpts } from "@/lib/foundation/p-node/firebase";
import {
  AlertDialog,
  useShowDialog,
  useTryPopup,
} from "@/lib/interface/dialog";
import { postCourse } from "@/lib/meat/api";
import {
  addCourseToDashboard,
  DashboardContent,
  getDashboardContentPath,
} from "@/lib/meat/dashboard/utils";
import {
  getOnboardingDataPath,
  getSessionOnboardingData,
  isValidOnboardingData,
  setSessionOnboardingData,
} from "@/lib/meat/onboarding";
import { claimReferral } from "@/lib/meat/referral";
import { getSchoolFromEmail } from "@/lib/meat/schools";
import {
  Navigate,
  Outlet,
  createFileRoute,
  redirect,
  useLocation,
} from "@tanstack/react-router";
import { AxiosError } from "axios";
import { get, set } from "firebase/database";
import { useEffect, useRef } from "react";

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ location }) => {
    const user = await getFbUserPromise();

    if (user == null) {
      throw redirect({ to: "/login", search: { redirect: location.pathname } });
    }

    let creationTimestamp: number | undefined;

    try {
      creationTimestamp = new Date(user.metadata.creationTime!).getTime();
    } catch (e) {
      creationTimestamp = undefined;
    }

    const sessionOnboardingData = getSessionOnboardingData();
    const onboardingExistsForUser = (
      await get(fbDbRef(getOnboardingDataPath(user.uid)))
    ).exists();

    if (
      isValidOnboardingData(sessionOnboardingData) &&
      !onboardingExistsForUser
    ) {
      await set(
        fbDbRef(getOnboardingDataPath(user.uid)),
        sessionOnboardingData,
      );

      const identify = new ampl.Identify();
      identify.set("Institution Type", sessionOnboardingData.institutionType);

      if (sessionOnboardingData.institutionType === "higher_education") {
        identify.set("School", sessionOnboardingData.selectedSchoolId);
      }

      ampl.identify(identify);

      if (sessionOnboardingData.institutionType === "higher_education") {
        const dashboardContentNode = await fbPNodeQueryOpts<
          DashboardContent | undefined
        >(getDashboardContentPath(user.uid)).queryFn()!;

        for (const courseName of sessionOnboardingData.newCourseNames) {
          try {
            const courseId = await postCourse(
              sessionOnboardingData.selectedSchoolId,
              courseName,
            );

            await addCourseToDashboard(
              dashboardContentNode,
              sessionOnboardingData.selectedSchoolId,
              courseId,
            );
          } catch (e) {
            if (!(e instanceof AxiosError) || e.response?.status != 400) {
              throw e;
            }
          }
        }

        for (const courseId of sessionOnboardingData.selectedCourseIds) {
          await addCourseToDashboard(
            dashboardContentNode,
            sessionOnboardingData.selectedSchoolId,
            courseId,
          );
        }
      }

      setSessionOnboardingData({});
    } else if (
      creationTimestamp &&
      creationTimestamp > 1728530909000 &&
      !onboardingExistsForUser
    ) {
      throw redirect({ to: "/onboarding", search: { page: undefined } });
    }
  },
  component: () => <Wrapper />,
});

function Wrapper() {
  const user = useFbUser();
  const location = useLocation();

  const tryPopup = useTryPopup();
  const showDialog = useShowDialog();

  const startedClaiming = useRef(false);

  useEffect(() => {
    if (startedClaiming.current) {
      return;
    }

    startedClaiming.current = true;

    const referralCode = getLocalStorageItem<string>("referralCode");

    if (referralCode != null) {
      tryPopup(async () => {
        try {
          await claimReferral(referralCode);
          ampl.track("Referral Claimed", {
            code: referralCode,
          });
          showDialog((onClose) => (
            <AlertDialog
              className="min-w-[400px]"
              onClose={onClose}
              title="Referral Claimed"
              message="To complete your referral, please go to a worksheet and send a chat."
            />
          ));
        } catch (e) {
          ampl.track("Referral Error Occurred", {
            code: referralCode,
            error: String(e),
          });
          throw e;
        } finally {
          localStorage.removeItem("referralCode");
        }
      });
    }
  }, [tryPopup, showDialog]);

  if (user == null) {
    return <Navigate to="/login" search={{ redirect: location.pathname }} />;
  }

  return <Outlet />;
}
