import { env } from "@/env";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { Capacitor } from "@capacitor/core";
import { isIpad } from "./capacitor/misc";

if (typeof window !== "undefined") {
  amplitude.init(env.amplitudeApiKey, undefined, {
    serverUrl: "https://amplitude2.pensieve.co/2/httpapi",
  });
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
  });
  amplitude.add(sessionReplayTracking);

  if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios") {
    const identity = new amplitude.Identify();
    identity.set("Capacitor Device Type", isIpad() ? "iPad" : "iPhone");
    amplitude.identify(identity);
  }

  amplitude.track("App Opened");
}

export const ampl = amplitude;
