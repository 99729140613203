import cn from "classnames";
import logoUrl from "@/assets/logo.png";
import { Link, useMatches } from "@tanstack/react-router";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  Suspense,
  useDeferredValue,
  useEffect,
} from "react";
import { AppBarProfile } from "./profile";
import { AppBarSpacer } from "./actions";
import { CenteredSpinner } from "@/lib/interface/spinner";

export function AppBarContainer({
  children,
  appBar,
}: {
  children?: ReactNode;
  appBar?: ReactNode;
}) {
  return (
    <div className="flex flex-col min-h-0 grow">
      {appBar ?? <AppBar />}
      <Suspense fallback={<CenteredSpinner />}>
        <div className="grow min-h-0">{children}</div>
      </Suspense>
    </div>
  );
}

export function AppBar({
  className,
  children,
}: {
  className?: string;
  children?: ReactNode;
}) {
  return (
    <nav
      style={{
        paddingTop: "env(safe-area-inset-top, 0px)",
        height: "calc(70px + env(safe-area-inset-top, 0px))",
      }}
      className={cn(
        "flex flex-row items-center h-[70px] px-[30px] border-b-border border-b-[1px] bg-white z-30 shrink-0",
        className,
      )}
    >
      <Suspense fallback={<DefaultAppBarContent />}>
        {children ?? <DefaultAppBarContent />}
      </Suspense>
    </nav>
  );
}

export type AppBarLogoProps = {
  to?: string;
};

export function AppBarLogo({ to }: AppBarLogoProps) {
  return (
    <Link to={to || "/"}>
      <img src={logoUrl} className="h-[45px]" />
    </Link>
  );
}

export function DefaultAppBarContent() {
  return (
    <>
      <AppBarLogo />
      <AppBarSpacer />
      <AppBarProfile />
    </>
  );
}

const AppBarCustomContentSetterContext = createContext<
  ((content: ReactNode | null | undefined) => void) | undefined
>(undefined);

const AppBarCustomContentContext = createContext<ReactNode | null | undefined>(
  null,
);

export const AppBarCustomContentProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [customContent, setCustomContent] = useState<
    ReactNode | null | undefined
  >(null);

  return (
    <AppBarCustomContentContext.Provider value={customContent}>
      <AppBarCustomContentSetterContext.Provider value={setCustomContent}>
        {children}
      </AppBarCustomContentSetterContext.Provider>
    </AppBarCustomContentContext.Provider>
  );
};

export function useAppBarCustomContentSetter() {
  const context = useContext(AppBarCustomContentSetterContext);
  if (!context) {
    throw new Error(
      "useAppBarCustomContentSetter must be used within a AppBarCustomContentSetterProvider",
    );
  }
  return context;
}

export function useSetAppBarCustomContent(
  content: ReactNode | null | undefined,
) {
  const setCustomContent = useAppBarCustomContentSetter();

  useEffect(() => {
    setCustomContent(content);
  }, [setCustomContent, content]);
}
