import { registerPlugin } from "@capacitor/core";

interface FirebasePlugin {
  getGoogleSignInCredentials({ clientId }: { clientId: string }): Promise<{
    idToken: string;
    accessToken: string;
  }>;
  getAppleSignInCredentials(): Promise<{
    identityToken: string;
    nonce: string;
  }>;
}

const FirebasePlugin = registerPlugin<FirebasePlugin>("Firebase");

export default FirebasePlugin;
