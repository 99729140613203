import {
  DatabaseReference,
  child,
  get,
  onValue,
  runTransaction,
  set,
} from "firebase/database";
import { ListenableNotifier } from "../listenable";
import { PNode, PNodeChildType, PNodeKeyType, usePNode } from "./p-node";
import { fbDbRef } from "../firebase";
import { useEffect, useRef } from "react";
import {
  QueryClient,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";

export class FbPNode<T> implements PNode<T> {
  private readonly root: FbPNode<unknown>;
  private rootData?: unknown;
  private rootNotifier?: ListenableNotifier;
  private children: Record<PNodeKeyType<T>, FbPNode<unknown>> = {} as Record<
    PNodeKeyType<T>,
    FbPNode<unknown>
  >;

  private _error: Error | null = null;
  public get error(): Error | null {
    return this._error;
  }

  private _isLoading: boolean = true;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(
    public readonly ref: DatabaseReference,
    root?: FbPNode<unknown>,
    private readonly path: (string | number | symbol)[] = [],
  ) {
    if (root == null) {
      this.root = this;
      this.rootData = {};
      this.rootNotifier = new ListenableNotifier(() =>
        onValue(
          this.ref,
          (snapshot) => {
            this.rootData = snapshot.val() ?? undefined;
            this._isLoading = false;
            this.root.rootNotifier!.notify();
          },
          (error) => {
            this._error = error;
            this._isLoading = false;
            this.root.rootNotifier!.notify();
          },
        ),
      );
    } else {
      this.root = root;
    }
  }

  child<K extends PNodeKeyType<T>>(key: K) {
    if (this.children[key] !== undefined) {
      return this.children[key] as FbPNode<PNodeChildType<T, K>>;
    } else {
      return (this.children[key] = new FbPNode<PNodeChildType<T, K>>(
        child(this.ref, key.toString()),
        this.root,
        [...this.path, key],
      ));
    }
  }

  async transact(update: (value: T) => T): Promise<T> {
    const result = await runTransaction(this.ref, (value: T) => {
      const updated = value === null ? update(undefined as T) : update(value);
      return updated === undefined ? null : updated;
    });
    return result.snapshot.val();
  }

  get value(): T {
    const pending = this.path.reduce<any>((value, key) => {
      if (typeof value === "object" && Array.isArray(value)) {
        return value[typeof key === "number" ? key : parseInt(key as string)];
      }

      if (typeof value === "object" && value != null) {
        return value[key];
      }

      return undefined;
    }, this.root.rootData);

    if (
      typeof pending === "object" &&
      pending != null &&
      !Array.isArray(pending) &&
      Object.keys(pending).length === 0
    ) {
      return undefined as T;
    }

    return pending;
  }

  set value(newValue: T) {
    const sanitizedValue =
      newValue !== undefined
        ? (JSON.parse(JSON.stringify(newValue)) as unknown)
        : null;
    if (this.path.length === 0) {
      this.root.rootData = sanitizedValue;
      this.root.rootNotifier!.notify();
    } else {
      if (this.root.rootData == null) {
        this.root.rootData = {};
      }

      const clone = structuredClone(this.root.rootData);
      let current: any = clone;

      for (let i = 0; i < this.path.length - 1; i++) {
        const key = this.path[i];
        if (current[key] == null) {
          current[key] = {};
        }
        current = current[key];
      }

      if (sanitizedValue != null) {
        current[this.path[this.path.length - 1]] = sanitizedValue;
      } else {
        delete current[this.path[this.path.length - 1]];
      }

      this.root.rootData = clone;

      this.root.rootNotifier!.notify();
    }

    set(this.ref, sanitizedValue);
  }

  get listenable(): ListenableNotifier {
    return this.root.rootNotifier!;
  }
}

export function fbPNodeQueryOpts<T>(path: string | null | undefined) {
  return {
    staleTime: Infinity,
    queryKey: ["fbPNode", path],
    queryFn: () => {
      if (path == null) {
        return null; // "Query data cannot be undefined. Please make sure to return a value other than undefined from your query function." –Tanner Linsley
      }

      const node = new FbPNode<T>(fbDbRef(path));

      return new Promise<FbPNode<T>>((resolve, reject) => {
        if (!node.isLoading) {
          resolve(node);
          return;
        }

        let completed = false;

        const unsubscribe = node.listenable.subscribe(() => {
          if (completed) return;

          if (node.error) {
            unsubscribe();
            reject(node.error);
            completed = true;
          } else if (!node.isLoading) {
            unsubscribe();
            resolve(node);
            completed = true;
          }
        });

        if (!completed && !node.isLoading) {
          unsubscribe();
          resolve(node);
          completed = true;
        }
      });
    },
  } as const;
}

export function useFbPNode<T>(path: string): FbPNode<T>;

export function useFbPNode<T>(path: null | undefined): undefined;

export function useFbPNode<T>(
  path: string | null | undefined,
): FbPNode<T> | undefined;

export function useFbPNode<T>(
  path: string | null | undefined,
): FbPNode<T> | undefined {
  const { data: node } = useSuspenseQuery(fbPNodeQueryOpts<T>(path));

  if (node?.error) {
    throw node.error;
  }

  return node ?? undefined;
}

export function useFbPNodeNoSuspense<T>(
  path: string | null | undefined,
): UseQueryResult<FbPNode<T>>;

export function useFbPNodeNoSuspense<T>(
  path: string | null | undefined,
): UseQueryResult<FbPNode<T>> {
  return useQuery(fbPNodeQueryOpts<T>(path));
}

export function useFbValue<T>(
  path: null | undefined,
  options: { nullableFallback: NonNullable<T> },
): [NonNullable<T>, (value: T) => void];

export function useFbValue<T>(
  path: null | undefined,
  options: { nullableFallback: T },
): [T, (value: T) => void];

export function useFbValue<T>(
  path: null | undefined,
  options?: { nullableFallback?: undefined },
): [undefined, (value: T) => void];

export function useFbValue<T>(
  path: string | null | undefined,
  options: { nullableFallback: NonNullable<T> },
): [NonNullable<T>, (value: T) => void];

export function useFbValue<T>(
  path: string,
  options?: { nullableFallback?: T },
): [T, (value: T) => void];

export function useFbValue<T>(
  path: string | null | undefined,
  options?: { nullableFallback?: T },
): [T | undefined, (value: T) => void];

export function useFbValue<T>(
  path: string | null | undefined,
  options?: { nullableFallback?: T },
): [T | undefined, (value: T) => void] {
  const node = useFbPNode<T>(path);
  const [data] = usePNode(node, options);

  return [data, (value: T) => (node!.value = value)] as const;
}

export async function getFbPNode<T>(
  queryClient: QueryClient,
  path: string,
): Promise<FbPNode<T>> {
  const query = await queryClient.fetchQuery(fbPNodeQueryOpts<T>(path));
  return query!;
}

export function useOnceForever(
  uid: string,
  key: string | null,
  fn: () => void,
) {
  const prefix = "useOnceForever";

  const fnRef = useRef(fn);
  fnRef.current = fn;

  const localStorageKey = `_${uid}_${prefix}_${key}`;
  const firebaseRef = fbDbRef(`${prefix}/${uid}/${key}`);

  const check = useMutation({
    mutationFn: async () => {
      if (key == null) return;

      const localStorageFlag = localStorage.getItem(localStorageKey);

      const firebaseFlagResult = (
        await get(fbDbRef(`${prefix}/${uid}/${key}`))
      ).val() as boolean | null;

      console.log(firebaseFlagResult, localStorageFlag);

      if (!firebaseFlagResult && localStorageFlag !== "true") {
        fnRef.current();
        localStorage.setItem(localStorageKey, "true");
        set(firebaseRef, true);
      }
    },
  });

  const checkRef = useRef(check);
  checkRef.current = check;

  useEffect(() => {
    checkRef.current.mutate();
  }, [key]);
}
