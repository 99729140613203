import { getWorksheetPath } from "@/lib/meat/worksheets";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_auth/schools/$schoolId/$department/$courseId/$worksheetId/edit/",
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: "/edit-worksheet",
      search: {
        worksheetPath: getWorksheetPath(
          undefined,
          params.courseId,
          params.worksheetId,
        ),
      },
    });
  },
});
