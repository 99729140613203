import { User } from "firebase/auth";
import { useLocalStorage } from "../foundation/local-storage";
import { fbPNodeQueryOpts, useFbValue } from "../foundation/p-node/firebase";
import { QueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import Fuse from "fuse.js";
import { PCombobox } from "../interface/combobox";

export type SchoolId = string;

export type School = {
  name: string;
  domains: string[] | null;
  keywords?: string | null;
  payment_plan_name?: string;
};

export function getSchoolFromEmail(
  user: User | null,
  schools: Record<SchoolId, School>,
) {
  if (!user || !user.email || !user.emailVerified) {
    return null;
  }

  const userDomain = user.email.split("@")[1];

  for (const [id, school] of Object.entries(schools ?? [])) {
    for (const domain of school.domains ?? []) {
      if (domain === userDomain || userDomain.endsWith(`.${domain}`))
        return {
          id,
          ...school,
        };
    }
  }

  return null;
}

export function useSchoolFromEmail(user: User | null) {
  const [schools] = useSchools();

  return useMemo(
    () => getSchoolFromEmail(user, schools ?? {}),
    [user, schools],
  );
}

export function useSchool(schoolId: SchoolId) {
  return useFbValue<School>(`/schools/${schoolId}`);
}

export function getSchoolsPath() {
  return "/schools";
}

export function useSchools() {
  return useFbValue<Record<SchoolId, School> | undefined>("/schools", {
    nullableFallback: {},
  });
}

export function useDiscussSchools() {
  return useFbValue<Record<SchoolId, School> | undefined>("/discuss_schools", {
    nullableFallback: {},
  });
}

export async function getSchools(
  queryClient: QueryClient,
): Promise<Record<SchoolId, School>> {
  const node = await queryClient.ensureQueryData(
    fbPNodeQueryOpts<Record<SchoolId, School>>("/schools"),
  );

  if (node == null) {
    throw new Error("Failed to get schools");
  }

  return node.value;
}

export function usePreferredSchool() {
  return useLocalStorage<string | null>(`preferred-school`, null);
}

export function SchoolsFuseSearchComboBox({
  className,
  hideKeywords,
  school,
  setSchool,
}: {
  className?: string;
  hideKeywords?: boolean;
  school: (School & { id: string }) | undefined;
  setSchool: (school: (School & { id: string }) | undefined) => void;
}) {
  const [schools] = useSchools();

  const schoolsWithId = useMemo(() => {
    return Object.entries(schools ?? {}).map(([schoolId, school]) => ({
      ...school,
      id: schoolId,
    }));
  }, [schools]);

  const fuseSearch = useMemo(() => {
    return new Fuse(schoolsWithId, {
      keys: ["name", "keywords", "id"],
      threshold: 0.1,
    });
  }, [schoolsWithId]);

  return (
    <PCombobox
      className={className}
      value={school ?? null}
      onChange={(value) => setSchool(value ?? undefined)}
      values={schoolsWithId}
      getLabel={(school) => school?.name ?? ""}
      getOptionLabel={(school) => {
        return (
          <div className="flex flex-col">
            <h1>{school?.name ?? ""}</h1>
            {!hideKeywords && (
              <h2 className="text-sm text-foreground/70 font-base text-wrap">
                {school?.keywords}
              </h2>
            )}
          </div>
        );
      }}
      getKey={(school) => school?.id ?? ""}
      fuse={fuseSearch}
    />
  );
}
