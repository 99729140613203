import { ampl } from "@/lib/foundation/amplitude";
import { fbDbRef, getFbUserPromise } from "@/lib/foundation/firebase";
import { getFbPNode } from "@/lib/foundation/p-node/firebase";
import {
  getOnboardingDataPath,
  getSessionOnboardingData,
  setSessionOnboardingData,
} from "@/lib/meat/onboarding";
import { getSchoolFromEmail, getSchoolsPath, School } from "@/lib/meat/schools";
import { QueryClient } from "@tanstack/react-query";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { get } from "firebase/database";

type SearchParams = {
  page: number | string | undefined;
};

export const Route = createFileRoute("/onboarding/")({
  beforeLoad: async ({
    search,
    context,
  }: {
    search: SearchParams;
    context: { queryClient: QueryClient };
  }) => {
    const user = await getFbUserPromise();

    if (user) {
      const existingOnboardingData = await get(
        fbDbRef(getOnboardingDataPath(user.uid)),
      );

      if (existingOnboardingData.exists()) {
        throw redirect({ to: "/" });
      }
    }

    ampl.track("Onboarding Viewed");

    if (user && (search.page === 1 || search.page == undefined)) {
      const schoolsNode = await getFbPNode<Record<string, School>>(
        context.queryClient,
        getSchoolsPath(),
      );
      const schoolFromEmail = getSchoolFromEmail(user, schoolsNode.value);

      if (schoolFromEmail) {
        setSessionOnboardingData({
          ...getSessionOnboardingData(),
          institutionType: "higher_education",
          selectedSchoolId: schoolFromEmail.id,
        });
        throw redirect({ search: { page: 3 } });
      } else {
        throw redirect({ search: { page: 2 } });
      }
    }
  },

  validateSearch: (params) => {
    return {
      page: params.page,
    } as SearchParams;
  },
});
