import { cn } from "@/lib/foundation/misc";

import { IconButton } from "@/lib/interface/button";
import { createContext, useContext, useState } from "react";

import Pencil from "@/assets/pencil.svg?react";
import Eraser from "@/assets/eraser.svg?react";
import Lasso from "@/assets/lasso.svg?react";
import { Capacitor } from "@capacitor/core";
import PencilPlugin from "@/lib/foundation/capacitor/pencil-plugin";
import { isIpad } from "@/lib/foundation/capacitor/misc";

export type AppBarTabletTool = "pen" | "eraser" | "lasso";

const AppBarTabletToolContext = createContext<
  [AppBarTabletTool, (tool: AppBarTabletTool) => void]
>(["pen", () => {}]);

export function AppBarTabletToolProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [tool, setTool] = useState<AppBarTabletTool>("pen");

  if (Capacitor.getPlatform() === "ios") {
    PencilPlugin.addListener("pencilDoubleTapped", () => {
      console.log("pencilDoubleTapped");
      if (tool === "pen") {
        setTool("eraser");
      } else {
        setTool("pen");
      }
    });
  }

  return (
    <AppBarTabletToolContext.Provider value={[tool, setTool]}>
      {children}
    </AppBarTabletToolContext.Provider>
  );
}

export function useAppBarTabletTool() {
  return useContext(AppBarTabletToolContext);
}

export function AppBarTabletToolbar({
  tool,
  setTool,
}: {
  tool: AppBarTabletTool;
  setTool: (tool: AppBarTabletTool) => void;
}) {
  if (!isIpad()) {
    return null;
  }

  return (
    <>
      <div className="flex h-full justify-center items-stretch py-[18px] px-[20px]">
        <div className="w-[1px] bg-border"></div>
      </div>
      <div className="flex flex-row gap-4">
        <IconButton
          onClick={() => {
            setTool("pen");
          }}
        >
          <Pencil
            className={cn(
              "w-[24px] h-[24px] fill-foreground stroke-foreground",
              tool === "pen" && "fill-primary stroke-primary",
            )}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            setTool("eraser");
          }}
        >
          <Eraser
            className={cn(
              "w-[24px] h-[24px] ",
              tool === "eraser"
                ? " stroke-primary fill-primary"
                : "stroke-foreground fill-foreground",
            )}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            setTool("lasso");
          }}
        >
          <Lasso
            className={cn(
              "w-[24px] h-[24px] ",
              tool === "lasso"
                ? " stroke-primary fill-primary"
                : "stroke-foreground fill-foreground",
            )}
          />
        </IconButton>
      </div>
    </>
  );
}
