import { Key, useEffect, useRef, useState } from "react";
import { Listbox } from "@headlessui/react";
import DropdownArrow from "@/assets/dropdown-arrow";
import { cn } from "../foundation/misc";

const sentinel =
  "sentinel key and value for Listbox because the headlessui Library is so dumb and badly designed 😒" as const;

export default function DropdownSelector<T>({
  className,
  values,
  value,
  getKey,
  getLabel,
  onChange,
  placeholder = "Select",
  placeholderSelectable = false,
  icon,
  dropUp = false,
  disabled = false,
  animateChange = false,
}: {
  className?: string;
  values: T[];
  value: T | null | undefined;
  getKey: (value: T) => Key;
  getLabel: (value: T) => string;
  onChange: (value: T | null) => void;
  placeholder?: string;
  placeholderSelectable?: boolean;
  icon?: React.ReactNode;
  dropUp?: boolean;
  disabled?: boolean;
  animateChange?: boolean;
}) {
  const valueKey = value ? getKey(value) : sentinel;
  const lastValueKeyRef = useRef(valueKey);

  const [isAnimating, setIsAnimating] = useState(false);
  const [animatingClassName, setAnimatingClassName] = useState("");
  useEffect(() => {
    if (animateChange && lastValueKeyRef.current !== valueKey) {
      lastValueKeyRef.current = valueKey;
      setIsAnimating(true);
      setAnimatingClassName("text-primary font-bold");
      const timeout1 = setTimeout(() => {
        setAnimatingClassName("");
      }, 100);
      const timeout2 = setTimeout(() => {
        setIsAnimating(false);
      }, 1000);
      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      };
    }
  }, [valueKey, animateChange]);

  return (
    <Listbox
      value={value ?? sentinel}
      onChange={(value) => onChange(value === sentinel ? null : value)}
      disabled={disabled}
    >
      <Listbox.Button
        className={cn(
          "border-border border pl-[20px] pr-[15px] h-[52px] font-medium text-foreground rounded-lg flex flex-row items-center outline-primary box-border relative",
          value || "text-opacity-40",
          className,
        )}
      >
        {icon && <div className="mr-[8px]">{icon}</div>}
        <span
          key={String(isAnimating)}
          className={cn(
            "text-ellipsis overflow-hidden text-nowrap transition-[font-weight, color] duration-[1500ms]",
            animatingClassName,
          )}
        >
          {value ? getLabel(value) : placeholder}
        </span>
        <div className="flex-1" />
        <DropdownArrow className="ml-5 shrink-0" />
        <Listbox.Options
          className={cn(
            "absolute z-10  outline-primary bg-white dropdown-shadow max-h-96 min-w-[159px] rounded-md text-base overflow-y-auto dumb-scrollbar",
            dropUp
              ? "bottom-full mb-[17px] right-0"
              : "top-full mt-[17px] left-0",
          )}
        >
          {[
            ...[...(placeholderSelectable ? [null] : []), ...values].map(
              (value) => (
                <Listbox.Option
                  key={value ? getKey(value) : sentinel}
                  value={value}
                  className={({ active }) =>
                    cn(
                      "text-nowrap select-none relative py-3 px-5 text-left max-w-80 overflow-hidden text-ellipsis",
                      active && "bg-primary bg-opacity-5",
                      value === null
                        ? "text-opacity-40 text-foreground"
                        : "text-opacity-100 text-foreground",
                    )
                  }
                >
                  {value != null ? getLabel(value) : placeholder}
                </Listbox.Option>
              ),
            ),
          ]}
        </Listbox.Options>
      </Listbox.Button>
    </Listbox>
  );
}
