import { cn } from "../foundation/misc";

export function Symbol({
  children,
  className,
  fill = false,
}: { fill?: boolean } & React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn("material-symbols-outlined", className)}
      style={{ fontVariationSettings: fill ? '"FILL" 1' : "" }}
    >
      {children}
    </span>
  );
}
