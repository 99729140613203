import { useClientQuery } from "../foundation/client";
import { useFbPNode, useFbValue } from "../foundation/p-node/firebase";
import { DateTime } from "luxon";
import { env } from "@/env";
import { v4 } from "uuid";
import { useEffect } from "react";
import { set } from "firebase/database";
import { fbDbRef } from "../foundation/firebase";

export const paymentGracePeriodDays = 3;

export interface PaymentState {
  payment_plan: boolean;
  paid_through: Day;
  platform: "stripe" | "app_store";
  price_per_month: number;
  currency: string;
}

export function useAppAccountToken(uid: string) {
  const [appAccountToken, setAppAccountToken] = useFbValue<string | undefined>(
    `/app_store/uid_to_app_account_token/${uid}`,
  );

  console.log("appAccountToken", appAccountToken);

  const tokenToReturn = appAccountToken ?? v4();

  useEffect(() => {
    if (appAccountToken == null) {
      console.log("setting app account token", tokenToReturn);
      setAppAccountToken(tokenToReturn);
    }

    set(fbDbRef(`/app_store/app_account_token_to_uid/${tokenToReturn}`), uid);
  }, [tokenToReturn, appAccountToken]);

  return tokenToReturn;
}

export function useUserPayment(uid: string) {
  return useFbValue<PaymentState | undefined>(`/payment/${uid}`);
}

export function useSubscribeLink() {
  const { data } = useClientQuery<string>(`/payment/monthly_subscribe_link`, {
    refetchInterval: 600 * 1000,
  });
  return data;
}

export function useCustomerPortalLink() {
  const { data } = useClientQuery<string>(`/payment/customer_portal_link`, {
    refetchInterval: 600 * 1000,
  });
  return data;
}

export function getTimeFor(day: Day, end?: boolean) {
  let date = DateTime.fromObject(day, { zone: env.timeZone });
  if (end) {
    date = date.plus({ days: 1 });
  }
  return date.toMillis();
}

export function useSubscriptionActive(uid: string) {
  const [userPayment] = useUserPayment(uid);

  if (!userPayment?.paid_through) {
    return false;
  }

  const paidThroughDate = DateTime.fromObject(userPayment.paid_through, {
    zone: env.timeZone,
  }).plus({ days: paymentGracePeriodDays });

  const now = DateTime.local({ zone: env.timeZone });

  if (now < paidThroughDate) {
    return true;
  }

  return false;
}

type Day = {
  year: number;
  month: number;
  day: number;
};

type DayInYear = {
  month: number;
  day: number;
};
