import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from "react";
import { useFbPNode, useFbValue } from "../foundation/p-node/firebase";
import { useFbUser } from "../foundation/firebase";
import { IconButton } from "./button";

interface TutorialContextType {
  launchTutorial: (tutorialId: string) => void;
}

const TutorialContext = createContext<TutorialContextType | undefined>(
  undefined,
);

interface TutorialPopupProviderProps {
  children: ReactNode;
  tutorialIdMap: Record<string, ReactNode[]>;
}

interface TutorialState {
  tutorialId: string;
  page: number;
}

export const TutorialPopupProvider: FC<TutorialPopupProviderProps> = ({
  children,
  tutorialIdMap,
}) => {
  const uid = useFbUser()?.uid;

  if (!uid) {
    return children;
  }

  return (
    <Inner uid={uid} tutorialIdMap={tutorialIdMap}>
      {children}
    </Inner>
  );
};

function Inner({
  uid,
  tutorialIdMap,
  children,
}: {
  uid: string;
  tutorialIdMap: Record<string, ReactNode[]>;
  children: ReactNode;
}) {
  const [tutorialState, setTutorialState] = useFbValue<
    TutorialState | undefined
  >(`/tutorial_states/${uid}`);

  const launchTutorial = (tutorialId: string) => {
    if (tutorialIdMap[tutorialId] && tutorialState?.tutorialId !== tutorialId) {
      setTutorialState({ tutorialId, page: 0 });
    }
  };

  const closeModal = () => {
    setTutorialState(undefined);
  };

  const nextPage = () => {
    if (tutorialState) {
      const pages = tutorialIdMap[tutorialState.tutorialId];
      if (tutorialState.page < pages.length - 1) {
        setTutorialState({ ...tutorialState, page: tutorialState.page + 1 });
      } else {
        closeModal();
      }
    }
  };

  const goToPage = (page: number) => {
    if (tutorialState) {
      setTutorialState({ ...tutorialState, page });
    }
  };

  return (
    <TutorialContext.Provider value={{ launchTutorial }}>
      {children}
      {tutorialState && (
        <div className="fixed flex flex-col items-center overflow-y-auto w-full top-0 left-0 h-full bg-black bg-opacity-50 z-50 py-24">
          <div className="relative bg-white rounded-lg p-6 py-5 w-11/12 max-w-2xl my-auto">
            {/* Close Button */}
            <button
              className="absolute top-5 right-4 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              &#x2715;
            </button>
            {/* Tutorial Content */}
            <div className="mb-4">
              {tutorialIdMap[tutorialState.tutorialId][tutorialState.page]}
            </div>
            {/* Footer: Page Indicator and Next/Done Button */}
            <div className="flex justify-between items-center">
              <div className="flex gap-0.5">
                {tutorialIdMap[tutorialState.tutorialId].map((_, index) => (
                  <IconButton className="p-1" onClick={() => goToPage(index)}>
                    <span
                      key={index}
                      className={`h-2 w-2 rounded-full ${
                        index === tutorialState.page
                          ? "bg-primary"
                          : "bg-gray-300"
                      }`}
                    ></span>
                  </IconButton>
                ))}
              </div>
              <button
                className="bg-primary text-white px-4 py-2 rounded"
                onClick={nextPage}
              >
                {tutorialState.page <
                tutorialIdMap[tutorialState.tutorialId].length - 1
                  ? "Next"
                  : "Done"}
              </button>
            </div>
          </div>
        </div>
      )}
    </TutorialContext.Provider>
  );
}

export const useLaunchTutorial = () => {
  const context = useContext(TutorialContext);

  if (!context) {
    throw new Error(
      "useLaunchTutorial must be used within a TutorialPopupProvider",
    );
  }

  return context.launchTutorial;
};
