import { useMemo, useRef } from "react";
import { ListenableNotifier } from "../listenable";
import { PNode, PNodeChildType, PNodeKeyType } from "./p-node";

type Key = string | number | symbol;

type RootMemoryPNodeConstructorArgs<RootT> = {
  root?: null | undefined;
  rootData: RootT;
  path: [];
};

type NonRootMemoryPNodeConstructorArgs<RootT> = {
  root: MemoryPNode<RootT, RootT>;
  rootData?: undefined;
  path: [Key, ...Key[]] | [...Key[], Key];
};

type MemoryPNodeConstructorArgs<T, RootT> = [T] extends [RootT]
  ? [RootT] extends [T]
    ?
        | RootMemoryPNodeConstructorArgs<RootT>
        | NonRootMemoryPNodeConstructorArgs<RootT>
    : NonRootMemoryPNodeConstructorArgs<RootT>
  : NonRootMemoryPNodeConstructorArgs<RootT>;

export class MemoryPNode<T, RootT = T> implements PNode<T> {
  private readonly root: MemoryPNode<RootT, RootT>;
  private rootData: RootT | undefined;
  private rootNotifier: ListenableNotifier;
  private path: Key[];
  private children: Record<PNodeKeyType<T>, MemoryPNode<unknown, RootT>> =
    {} as Record<PNodeKeyType<T>, MemoryPNode<unknown, RootT>>;

  constructor(args: MemoryPNodeConstructorArgs<T, RootT>) {
    this.path = args.path;
    if (args.root == null) {
      this.root = this as unknown as MemoryPNode<RootT, RootT>;
      this.rootData = args.rootData;
      this.rootNotifier = new ListenableNotifier();
    } else {
      this.root = args.root;
      // this.rootData = args.root.rootData;
      this.rootNotifier = args.root.rootNotifier;
    }
  }

  child<K extends PNodeKeyType<T>>(key: K) {
    if (this.children[key] !== undefined) {
      return this.children[key] as MemoryPNode<PNodeChildType<T, K>, RootT>;
    } else {
      return (this.children[key] = new MemoryPNode<PNodeChildType<T, K>, RootT>(
        {
          root: this.root,
          path: [...this.path, key],
        },
      ));
    }
  }

  transact(update: (value: T) => T): T {
    return (this.value = update(structuredClone(this.value)));
  }

  get value(): T {
    return this.path.reduce(
      (value, key) => (value !== undefined ? (value as any)[key] : undefined),
      this.root.rootData!,
    ) as unknown as T;
  }

  set value(newValue: T) {
    const sanitizedValue =
      newValue !== undefined ? (structuredClone(newValue) as T) : undefined;
    if (this.path.length === 0) {
      this.rootData = sanitizedValue as unknown as RootT;
      this.rootNotifier.notify();
    } else {
      const clone = structuredClone(this.root.rootData!);
      let current: any = clone;
      for (let i = 0; i < this.path.length - 1; i++) {
        const key = this.path[i];
        if (current[key] == null) {
          current[key] = {};
        }
        current = current[key];
      }
      if (sanitizedValue !== undefined) {
        current[this.path[this.path.length - 1]] = sanitizedValue;
      } else {
        delete current[this.path[this.path.length - 1]];
      }
      this.root.rootData = clone;
      this.rootNotifier.notify();
    }
  }

  get listenable(): ListenableNotifier {
    return this.rootNotifier;
  }
}

export function useMemoryPNode<T>(initialValue: T) {
  const firstTime = useRef(true);
  const initialValueRef = useRef<T>();
  if (firstTime.current) {
    initialValueRef.current = initialValue;
    firstTime.current = false;
  }
  return useMemo(
    () =>
      new MemoryPNode<T, T>({
        rootData: initialValueRef.current as T,
        path: [],
      }),
    [],
  );
}
