import { Dialog } from "@headlessui/react";
import { title } from "process";
import { cn, useAsyncFn } from "../foundation/misc";
import { Button } from "../interface/button";
import { DialogPanel, useTryPopup } from "../interface/dialog";
import { getImpersonateToken } from "./api";
import { TextField } from "../interface/text-field";
import { useContext, useEffect, useState } from "react";
import { signInWithCustomToken, signOut, User } from "firebase/auth";
import {
  fbAuth,
  getFbUser,
  getFbUserPromise,
  useFbUser,
} from "../foundation/firebase";
import { createContext } from "react";
import { createPortal } from "react-dom";

const ImpersonateUserContext = createContext<
  [User | undefined, (user: User | undefined) => void] | undefined
>(undefined);

export function ImpersonateUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [impersonatedUser, setImpersonatedUser] = useState<User | undefined>(
    undefined,
  );

  const user = useFbUser();

  useEffect(() => {
    if (user == null && impersonatedUser != null) {
      setImpersonatedUser(undefined);
    }
  }, [user, impersonatedUser]);

  return (
    <ImpersonateUserContext.Provider
      value={[impersonatedUser, setImpersonatedUser]}
    >
      {children}
      {impersonatedUser &&
        createPortal(
          <div className="flex flex-col fixed h-full w-full top-0 left-0 gap-16 justify-center items-center z-50 pointer-events-none">
            <div className="w-[400%] p-4 bg-red-500/10 flex flex-col items-center justify-center font-medium text-lg text-red-500/70 rotate-45">
              Impersonating {impersonatedUser.email}
            </div>
          </div>,
          document.body,
        )}
    </ImpersonateUserContext.Provider>
  );
}

function useImpersonatedUser() {
  const context = useContext(ImpersonateUserContext);

  if (context == null) {
    throw new Error(
      "useImpersonatedUser must be used within an ImpersonateUserProvider",
    );
  }

  return context;
}

export function ImpersonateUserDialog({ onClose }: { onClose: () => void }) {
  const tryPopup = useTryPopup();
  const [impersonatedUser, setImpersonatedUser] = useImpersonatedUser();
  const [confirm, confirming] = useAsyncFn(() =>
    tryPopup(async () => {
      const token = await getImpersonateToken(uidOrEmail);
      onClose();
      await signOut(fbAuth);

      const userCredential = await signInWithCustomToken(fbAuth, token);

      setImpersonatedUser(userCredential.user);
    }),
  );

  const [uidOrEmail, setUidOrEmail] = useState("");

  return (
    <DialogPanel className={cn("max-w-2xl")}>
      <Dialog.Title
        className={"font-heading text-[24px] font-medium text-center"}
      >
        Impersonate User
      </Dialog.Title>
      <Dialog.Description className={"opacity-70 mt-[6px] mb-4 text-center"}>
        Enter email or uid of user to impersonate
      </Dialog.Description>

      <TextField
        value={uidOrEmail}
        onChange={(e) => setUidOrEmail(e.target.value)}
      />

      <div className="flex flex-row gap-4">
        <Button className="mt-[30px] basis-0 grow" outline onClick={onClose}>
          Close
        </Button>
        <Button
          disabled={confirming || uidOrEmail.length === 0}
          loading={confirming}
          className="mt-[30px] basis-0 grow"
          onClick={confirm}
        >
          Impersonate
        </Button>
      </div>
    </DialogPanel>
  );
}
