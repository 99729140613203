import { EnvType } from "./env-type";

export const devEnv: EnvType = {
  sentryEnv: "dev",
  shouldSendAlerts: false,
  checkForDuplicates: false,
  baseUrl: "https://pensieve-discuss-server-jsac74jb2q-uc.a.run.app/api",
  newBaseUrl: "https://pensieve-server-jsac74jb2q-uc.a.run.app/api",
  backendHostname: "pensieve-discuss-server-78503728458.us-central1.run.app",
  websocketProtocol: "wss://",
  assetsBaseUrl: "https://storage.googleapis.com/pensieve-dev-discuss-public",
  amplitudeApiKey: "f24d363fc8e6c320122e639c7a56c24b",
  examUploadUrl: "https://iixsawhn94f.typeform.com/to/Vbkg0jVm",
  timeZone: "America/Los_Angeles",
  firebaseConfig: {
    apiKey: "AIzaSyATuRlMi4fDls8WJQGIBrTExUS2uodjswU",
    authDomain: "tutor.dev.pensieve.co",
    databaseURL: "https://pensieve-discuss-dev-default-rtdb.firebaseio.com",
    projectId: "pensieve-discuss-dev",
    storageBucket: "pensieve-discuss-dev.appspot.com",
    messagingSenderId: "78503728458",
    appId: "1:78503728458:web:d97f004ea0d461246a1db3",
  },
  firebaseAppleClientId:
    "78503728458-flv1cgmpa4ti4ok5ja564v735kmv3c00.apps.googleusercontent.com",
  intercomIosApiKey: "ios_sdk-1a2747152f16195dff7dbd625961f4ca61826393",
  intercomIosAppId: "bc2uouqo",
};

export const localDevEnv: EnvType = {
  ...devEnv,
  baseUrl: "http://127.0.0.1:8000/api",
  signingBaseUrl: devEnv.baseUrl,
  backendHostname: "127.0.0.1:8000",
  websocketProtocol: "ws://",
};

export const prodEnv: EnvType = {
  sentryEnv: "prod",
  shouldSendAlerts: true,
  checkForDuplicates: false,
  baseUrl: "https://pensieve-discuss-server-tvgqtfgsyq-uc.a.run.app/api",
  newBaseUrl: "https://pensieve-server-tvgqtfgsyq-uc.a.run.app/api",
  backendHostname: "pensieve-server-1090922385671.us-central1.run.app",
  websocketProtocol: "wss://",
  assetsBaseUrl: "https://storage.googleapis.com/pensieve-discuss-public",
  amplitudeApiKey: "d69a1b2a425d9c8adf8315977c8bba3a",
  examUploadUrl: "https://iixsawhn94f.typeform.com/to/Vbkg0jVm",
  timeZone: "America/Los_Angeles",
  firebaseConfig: {
    apiKey: "AIzaSyAUv2jjMMU7ew6327oYa9-wrHI8zktMMls",
    authDomain: "tutor.pensieve.co",
    databaseURL: "https://pensieve-discuss-default-rtdb.firebaseio.com",
    projectId: "pensieve-discuss",
    storageBucket: "pensieve-discuss.appspot.com",
    messagingSenderId: "1090922385671",
    appId: "1:1090922385671:web:3927bb002675380ce035a2",
  },
  firebaseAppleClientId:
    "1090922385671-1lsnk2qq0md7ajhcp0mp7fdblmrn8b3l.apps.googleusercontent.com",
  intercomIosApiKey: "ios_sdk-1a2747152f16195dff7dbd625961f4ca61826393",
  intercomIosAppId: "bc2uouqo",
};
