import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export function uniquesByValueOf<T extends { valueOf: () => string | number }>(
  values: T[],
) {
  const uniqueValuesMap = new Map(
    values.map((value) => [value.valueOf(), value]),
  );
  return Array.from(uniqueValuesMap.values());
}

export function useAsyncFn<P extends any[], R>(fn: (...args: P) => Promise<R>) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);

  const run = useCallback(async (...args: P) => {
    if (isLoadingRef.current) return;

    try {
      isLoadingRef.current = true;
      setIsLoading(true);
      await fnRef.current(...args);
    } finally {
      isLoadingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  return [run, isLoading] as const;
}

export function cn(...args: classNames.ArgumentArray) {
  return twMerge(classNames(...args));
}

export function useInterval(
  callback: () => void,
  delay: number,
  initialRun = false,
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const interval = setInterval(() => callbackRef.current(), delay);

    if (initialRun) {
      callbackRef.current();
    }
    return () => clearInterval(interval);
  }, [delay]);
}

export function useDebounced<T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
  atLeastEvery?: number,
) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<number | null>(null);
  const lastCallTimeRef = useRef(Date.now());

  callbackRef.current = callback;

  return useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }

      if (
        atLeastEvery != null &&
        Date.now() - lastCallTimeRef.current >= atLeastEvery
      ) {
        lastCallTimeRef.current = Date.now();
        callbackRef.current(...args);
        return;
      }

      timeoutRef.current = setTimeout(
        (() => {
          lastCallTimeRef.current = Date.now();
          callbackRef.current(...args);
        }) as TimerHandler,
        delay,
      );
    },
    [atLeastEvery, delay],
  );
}

export function useCallOnChange(callback: () => void, value: unknown) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const valueRef = useRef(value);
  useEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
      callbackRef.current();
    }
  }, [value]);
}

export function useOnceWhenAllDefined(fn: () => void, dependencies: unknown[]) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const ranRef = useRef(false);

  useEffect(() => {
    if (!ranRef.current && dependencies.every(Boolean)) {
      try {
        fnRef.current();
      } finally {
        ranRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export type NonUndefined<T> = T extends undefined ? never : T;

export function recordEntries<K extends string | number | symbol, V>(
  record: Record<K, V>,
) {
  return Object.entries(record).filter(([, value]) => value !== undefined) as [
    K,
    NonUndefined<V>,
  ][];
}
