import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/dashboard/upload/")({
  validateSearch: (params) => {
    return {
      upload_key: params.upload_key,
    } as {
      upload_key?: string;
    };
  },
});
