import { ampl } from "@/lib/foundation/amplitude";
import { fbAuth, getFbUserPromise } from "@/lib/foundation/firebase";
import { setLocalStorageItem } from "@/lib/foundation/local-storage";
import {
  loginWithEmailCode,
  loginWithTempAuthCode,
  tiktokTrack,
} from "@/lib/meat/api";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { signInWithCustomToken } from "firebase/auth";

type QueryArgs = {
  upload_key?: string;
  source?: string;
  redirect?: string;
  code?: string; // email code
  temp_auth_code?: string;
  referral_code?: string;
  ttclid?: string;
};

export const Route = createFileRoute("/login/")({
  beforeLoad: async ({
    search,
  }: {
    search: QueryArgs;
  }): Promise<{ signInError?: Error }> => {
    const sessionTtclid = sessionStorage.getItem("ttclid");

    const ttclid = search.ttclid ?? sessionTtclid ?? undefined;

    const identify = new ampl.Identify();
    if (ttclid) identify.set("ttclid", ttclid);

    ampl.identify(identify);

    let loggedIn = false;
    if (search.code || search.temp_auth_code) {
      if (search.referral_code) {
        setLocalStorageItem("referralCode", search.referral_code);
      }
      try {
        const customToken = search.code
          ? await loginWithEmailCode(search.code, search.source, search.ttclid)
          : await loginWithTempAuthCode(search.temp_auth_code!);
        await signInWithCustomToken(fbAuth, customToken);
        loggedIn = true;
      } catch (e) {
        return {
          signInError: e as Error,
        };
      }
    }
    loggedIn = loggedIn || (await getFbUserPromise()) != null;
    if (loggedIn) {
      if (search.upload_key) {
        throw redirect({
          to: `/dashboard/upload`,
          search: {
            upload_key: search.upload_key,
          },
        });
      }

      throw redirect({
        to: search.redirect || "/dashboard",
      });
    }

    if (sessionTtclid && !search.ttclid) {
      throw redirect({
        search: {
          ...search,
          ttclid: sessionTtclid,
        },
      });
    }

    tiktokTrack({
      event: "ViewContent",
      content_id: "login",
      ttclid,
    }).catch((e) => {
      console.error(e);
    });

    tiktokTrack({
      event: "View Log In",
      ttclid: search.ttclid ?? sessionTtclid ?? undefined,
    }).catch((e) => {
      console.error(e);
    });

    ampl.track("Log In Page Viewed", {
      Source: search.source,
      Redirect: search.redirect,
    });

    return {};
  },
  validateSearch: (params) => {
    return {
      source: params.source,
      redirect: params.redirect,
      code: params.code,
      temp_auth_code: params.temp_auth_code,
      referral_code: params.referral_code,
      ttclid: params.ttclid,
    } as QueryArgs;
  },
});
