import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import Ajv from "ajv";

import { env } from "@/env";

import { getClient, useClientQuery } from "../foundation/client";
import {
  FbBlock,
  FbQuestionBlock,
  FbQuestionBlockState,
  FbWorksheet,
} from "./worksheet/types";
import { WorksheetChatMessage } from "./worksheet-chat-sessions";
import { MarkdownFieldContent } from "./markdown-field";
import { FbMarkdownBlock } from "./worksheet/blocks/markdown";

const ajv = new Ajv();

export async function generateAltText(
  worksheet: FbWorksheet,
  blockId: string,
  mode: "image" | "solution",
) {
  return (await getClient(env.newBaseUrl).post(`/ai/generate_alt_text`, {
    worksheet,
    block_id: blockId,
    mode,
  })) as string;
}

export async function generateBubblesIfNeeded(
  syncPath: string,
  blockId: string,
  message: string,
  response: unknown,
) {
  try {
    return (await getClient().post(`/worksheets/generate_bubbles_if_needed`, {
      sync_path: syncPath,
      block_id: blockId,
      message,
      response,
    })) as string[];
  } catch (e: any) {
    console.error(e.message);
    return [];
  }
}

export async function generateBubbles(syncPath: string, blockId: string) {
  return (await getClient().post(`/worksheets/generate_bubbles`, {
    sync_path: syncPath,
    block_id: blockId,
  })) as string[];
}

export async function sendWorksheetCompleteEmail(
  userId: string,
  worksheetName: string,
  courseName: string,
  worksheetLink: string,
) {
  await getClient().post(`/worksheets/worksheet_complete_email`, {
    user_id: userId,
    worksheet_name: worksheetName,
    course_name: courseName,
    worksheet_link: worksheetLink,
  });
}

export async function sendWorksheetDeferredEmail(
  userId: string,
  worksheetName: string,
  courseName: string,
  statusLink: string,
) {
  await getClient().post(`/worksheets/worksheet_deferred_email`, {
    user_id: userId,
    worksheet_name: worksheetName,
    course_name: courseName,
    status_link: statusLink,
  });
}

export async function sendWorksheetRejectedEmail(
  userId: string,
  worksheetName: string,
  courseName: string,
  statusLink: string,
) {
  await getClient().post(`/worksheets/worksheet_rejected_email`, {
    user_id: userId,
    worksheet_name: worksheetName,
    course_name: courseName,
    status_link: statusLink,
  });
}

export async function getAssetUploadInfo({
  extension,
  contentType,
}: {
  extension: string;
  contentType: string;
}) {
  return (await getClient(env.signingBaseUrl).post("/assets/upload", {
    extension,
    "content-type": contentType,
  })) as {
    name: string;
    put_url: string;
  };
}

export async function uploadAsset({
  putUrl,
  contentType,
  file,
}: {
  putUrl: string;
  contentType: string;
  file: BodyInit;
}) {
  const response = await fetch(putUrl, {
    method: "PUT",
    headers: {
      "Content-Type": contentType,
    },
    body: file,
  });

  if (!response.ok) {
    throw new Error(
      `Failed to upload file ${response.statusText} ${response.status}`,
    );
  }
}

export type Grade = "indeterminate" | "incorrect" | "correct";

export type GradingMethod = "algorithmic" | "language_model";

export type GradingResult = {
  grade: Grade;
  gradingMethod: GradingMethod;
  markdown?: string;
};

export type DebugGradingResult =
  | {
      grade: Grade;
      gradingMethod: GradingMethod;
      markdown?: string;
      cost: number;
      log: string;
    }
  | {
      grade?: undefined;
      gradingMethod?: undefined;
      markdown?: string;
      cost: number;
      log: string;
    };

export type SolvingResult = {
  solved: FbQuestionBlock;
  answered: FbQuestionBlockState;
  cost?: undefined;
  log?: undefined;
};

export type DebugSolvingResult =
  | {
      solved?: undefined;
      answered?: undefined;
      cost: number;
      log: string;
    }
  | {
      solved: FbQuestionBlock;
      answered: FbQuestionBlockState;
      cost: number;
      log: string;
    };

export async function invokeGrader(
  worksheet: FbWorksheet,
  blockId: string,
  answer: unknown,
  scratchWork: string | MarkdownFieldContent,
): Promise<GradingResult>;

export async function invokeGrader(
  worksheet: FbWorksheet,
  blockId: string,
  answer: unknown,
  scratchWork: string | MarkdownFieldContent,
  debug: false,
): Promise<GradingResult>;

export async function invokeGrader(
  worksheet: FbWorksheet,
  blockId: string,
  answer: unknown,
  scratchWork: string | MarkdownFieldContent,
  debug: true,
): Promise<DebugGradingResult>;

export async function invokeGrader(
  worksheet: FbWorksheet,
  blockId: string,
  answer: unknown,
  scratchWork: string | MarkdownFieldContent,
  debug: boolean,
): Promise<GradingResult | DebugGradingResult>;

export async function invokeGrader(
  worksheet: FbWorksheet,
  blockId: string,
  answer: unknown,
  scratchWork: string | MarkdownFieldContent,
  debug: boolean = false,
): Promise<GradingResult | DebugGradingResult> {
  try {
    const rawResponse = (await getClient().post(`/grader`, {
      worksheet,
      block_id: blockId,
      answer,
      scratch_work: scratchWork,
      debug,
    })) as {
      grade: Grade;
      grading_method: GradingMethod;
      markdown: string | null;
      cost: number | null;
      log: string | null;
    };
    return {
      grade: rawResponse.grade,
      gradingMethod: rawResponse.grading_method,
      markdown: rawResponse.markdown ?? undefined,
      cost: rawResponse.cost ?? undefined,
      log: rawResponse.log ?? undefined,
    };
  } catch (e) {
    if (debug && e instanceof AxiosError) {
      const detail = e.response!.data.detail;
      if (validateGraderSolverErrorDetail(detail)) {
        return detail as DebugGradingResult;
      }
    }
    throw e;
  }
}

export async function invokeSolver(
  worksheet: FbWorksheet,
  blockId: string,
): Promise<SolvingResult>;

export async function invokeSolver(
  worksheet: FbWorksheet,
  blockId: string,
  debug: false,
): Promise<SolvingResult>;

export async function invokeSolver(
  worksheet: FbWorksheet,
  blockId: string,
  debug: true,
): Promise<DebugSolvingResult>;

export async function invokeSolver(
  worksheet: FbWorksheet,
  blockId: string,
  debug: boolean,
): Promise<SolvingResult | DebugSolvingResult>;

export async function invokeSolver(
  worksheet: FbWorksheet,
  blockId: string,
  debug: boolean = false,
): Promise<SolvingResult | DebugSolvingResult> {
  try {
    const rawResponse = (await getClient().post(`/solver`, {
      worksheet,
      block_id: blockId,
      debug,
    })) as {
      solved: FbQuestionBlock | null;
      answered: FbQuestionBlockState | null;
      cost: number | null;
      log: string | null;
    };
    return {
      solved: rawResponse.solved ?? undefined,
      answered: rawResponse.answered ?? undefined,
      cost: rawResponse.cost ?? undefined,
      log: rawResponse.log ?? undefined,
    } as SolvingResult | DebugSolvingResult;
  } catch (e) {
    if (debug && e instanceof AxiosError) {
      const detail = e.response!.data.detail;
      if (validateGraderSolverErrorDetail(detail)) {
        return e.response!.data.detail as DebugSolvingResult;
      }
    }
    throw e;
  }
}

const validateGraderSolverErrorDetail = ajv.compile({
  type: "object",
  properties: {
    cost: { type: "number" },
    log: { type: "string" },
  },
  required: ["cost", "log"],
});

export async function sendChat(
  syncPath: string,
  worksheet: FbWorksheet,
  message: string,
  blockId: string,
  numbering: string,
  response: unknown,
  gradingResult: GradingResult | undefined,
  writeOutput?: unknown,
  courseId?: string,
) {
  await getClient().post(`/worksheets/chat`, {
    sync_path: syncPath,
    worksheet,
    message,
    block_id: blockId,
    numbering,
    response,
    grading_result: gradingResult
      ? {
          grade: gradingResult.grade,
          grading_method: gradingResult.gradingMethod,
          markdown: gradingResult.markdown,
        }
      : undefined,
    write_output: writeOutput,
    course_id: courseId,
  });
}

export function useTempAuthCode() {
  return useClientQuery<string>("/user/temp_auth_code", {
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  }).data;
}

export async function loginWithTempAuthCode(code: string) {
  const customToken = (await getClient(env.signingBaseUrl).post(
    `/user/temp_auth_code`,
    {
      code,
    },
  )) as string;
  return customToken;
}

export async function loginWithEmail(
  email: string,
  redirect_url?: string | null,
  redirect_path?: string | null,
  source?: string | null,
  referral_code?: string | null,
  ttclid?: string | null,
  upload_key?: string | null,
) {
  return (await getClient().post(`/user/login_with_email`, {
    email,
    redirect_url,
    redirect_path,
    source,
    referral_code,
    ttclid,
    upload_key,
  })) as string | undefined;
}

export async function loginWithEmailCode(
  code: string,
  source?: string | null,
  ttclid?: string | null,
) {
  return (await getClient(env.signingBaseUrl).post(
    `/user/login_with_email/code`,
    {
      code,
      source,
      ttclid,
    },
  )) as string;
}

export async function loginWithCodeEmail(
  email: string,
): Promise<string | void> {
  return await getClient().post(`/user/login_with_code_email`, {
    email,
  });
}

export async function loginWithCodeEmailCode(email: string, code: string) {
  return (await getClient(env.signingBaseUrl).post(
    `/user/login_with_code_email/code`,
    {
      email,
      code,
    },
  )) as string;
}

export async function getIntercomHmac() {
  return (await getClient().get(`/user/intercom_hmac`)) as {
    user_id: string;
    user_hash: string;
  };
}

export async function getIntercomIosHmac() {
  return (await getClient().get(`/user/intercom_ios_hmac`)) as {
    user_id: string;
    user_hash: string;
  };
}

export async function getMathpixAuth() {
  const auth = (await getClient().get(`/user/mathpix_auth`)) as MathpixAuth;
  return auth;
}

export async function generateMissingAltTexts(
  worksheet: FbWorksheet,
  blockId: string,
) {
  return (await getClient().post(`/worksheets/generate_missing_alt_texts`, {
    worksheet,
    block_id: blockId,
  })) as string | undefined;
}

export async function getTempCheckoutSessionUrl() {
  return (await getClient().get(`/stripe/temp_checkout_session_url`)) as string;
}

export async function unsubscribe() {
  await getClient().post(`/payment/unsubscribe`);
}

export async function tiktokTrack({
  event,
  content_id,
  ttclid,
}: {
  event: string;
  content_id?: string;
  ttclid?: string;
}) {
  await getClient().post(`/tiktok/track`, {
    event,
    content_id,
    ttclid,
    url: window.location.href,
    referrer: document.referrer,
  });
}

export async function resubscribe(system: string) {
  await getClient().post(`/payment/resubscribe/${system}`);
}

export function useMathpixAuth() {
  const [auth, setAuth] = useState<MathpixAuth | null>(null);
  const isLoadingRef = useRef(false);
  const lastSetAuthTimeRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        isLoadingRef.current ||
        Date.now() - lastSetAuthTimeRef.current < 3 * 60 * 1000
      ) {
        return;
      }

      isLoadingRef.current = true;
      try {
        const auth = await getMathpixAuth();
        setAuth(auth);
        lastSetAuthTimeRef.current = Date.now();
      } catch (e) {
        console.error(e);
      } finally {
        isLoadingRef.current = false;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return auth;
}

export type MathpixAuth = {
  app_token: string;
  strokes_session_id: string;
};

export async function getUserIdFromEmail(email: string): Promise<string> {
  return (
    (await getClient().get(`/user/id_from_email/${encodeURI(email)}`)) as {
      user_id: string;
    }
  ).user_id;
}

export type ClaimsObject = Record<string, unknown>;

export async function getClaims(userId: string): Promise<ClaimsObject> {
  return (await getClient().get(`/user/${userId}/claims`)) as ClaimsObject;
}

export async function putClaims(
  userId: string,
  claims: ClaimsObject,
): Promise<void> {
  return await getClient().put(`/user/${userId}/claims`, claims);
}

export async function createUser(email: string) {
  return await getClient().post(`/user`, { email });
}

export async function postReviewThread(
  workerEmail: string,
  reviewerEmail: string,
  worksheetName: string,
  worksheetLink: string,
) {
  return (await getClient().post(`/slack/review-thread`, {
    reviewer_email: reviewerEmail,
    worker_email: workerEmail,
    worksheet_name: worksheetName,
    worksheet_link: worksheetLink,
  })) as string;
}

export async function postWorksheetProcessingTask(worksheetPath: string) {
  await getClient().post("/worksheet_processing_tasks", {
    worksheet_path: worksheetPath,
  });
}

export async function processWorksheet(userId: string, worksheetPath: string) {
  await getClient().post("/process_worksheet", {
    user_id: userId,
    worksheet_path: worksheetPath,
  });
}

export async function postWorksheetProcessingStarted(
  schoolId: string | undefined,
  courseId: string | undefined,
  worksheetId: string,
  userPdfUrl: string | undefined,
  userSolutionPdfUrl: string | undefined,
) {
  if (env.shouldSendAlerts) {
    return await getClient().post(`/slack/worksheet_processing_started`, {
      school_id: schoolId,
      course_id: courseId,
      worksheet_id: worksheetId,
      user_pdf_url: userPdfUrl,
      user_solution_pdf_url: userSolutionPdfUrl,
    });
  }
}

export async function postContentRequestForm(
  school: string,
  course: string,
  name: string,
  term: string,
  instructors: string,
  notes: string,
  blank?: File,
  solution?: File,
  promotional?: boolean,
) {
  return await getClient().postForm(`/uploads/content-request`, {
    school,
    course,
    name,
    term,
    instructors,
    notes,
    blank,
    solution,
    promotional,
  });
}

export async function classifyBlockContent(imageUrl: string) {
  return (
    await getClient().post(`/block-content-extraction/classify`, {
      image_url: imageUrl,
    })
  ).block_type as string;
}

export async function extractBlockContent(blockType: string, imageUrl: string) {
  return (await getClient().post(`/block-content-extraction/extract`, {
    block_type: blockType,
    image_url: imageUrl,
  })) as FbBlock;
}

export async function analyticsGenerateReason(
  worksheet: FbWorksheet,
  blockId: string,
  messages: WorksheetChatMessage[],
) {
  return (await getClient().post(`/analytics/generate-reason`, {
    worksheet,
    block_id: blockId,
    messages,
  })) as string;
}

/** returns courseId of created course */
export async function postCourse(
  schoolId: string,
  courseName: string,
): Promise<string> {
  return (await getClient().post("/courses", {
    school_id: schoolId,
    course_name: courseName,
  })) as string;
}

export async function publishWorksheet(
  draftWorksheetId: string,
  targetCourseId: string,
) {
  return await getClient().post(`/publish_worksheet`, {
    draft_worksheet_id: draftWorksheetId,
    target_course_id: targetCourseId,
  });
}

export async function postUserDeletionRequest() {
  return await getClient().post(`/user/deletion_request`);
}

export async function getImpersonateToken(uidOrEmail: string) {
  return await getClient().post(`/user/impersonation`, {
    uid_or_email: uidOrEmail,
  });
}
