import {
  AcademicSeason,
  AcademicTerm,
  ExamSpecifier,
} from "../foundation/exam-specifier";
import { FbPNode, useFbPNode, useFbValue } from "../foundation/p-node/firebase";
import { PNode, usePNode } from "../foundation/p-node/p-node";
import { NotFoundError } from "./error-screen";
import { WorksheetChatSession } from "./worksheet-chat-sessions";
import {
  FbWorksheet,
  FbWorksheetState,
  WorksheetHeader,
  WorksheetId,
} from "./worksheet/types";

export function worksheetPathToWorksheetHeaderPath(worksheetPath: string) {
  return worksheetPath.replace("worksheets/", "worksheet_headers/");
}

export function useWorksheetHeaders(courseId: null | undefined): undefined;

export function useWorksheetHeaders(
  courseId: string,
): Record<WorksheetId, WorksheetHeader>;

export function useWorksheetHeaders(
  courseId: string | null | undefined,
): Record<WorksheetId, WorksheetHeader> | undefined;

export function useWorksheetHeaders(
  courseId: string | null | undefined,
): Record<WorksheetId, WorksheetHeader> | undefined {
  const pNode = useFbPNode<Record<WorksheetId, WorksheetHeader> | undefined>(
    courseId != null ? `/worksheet_headers/${courseId}` : undefined,
  );
  const value = usePNode(pNode)[0];
  if (courseId != null) {
    return value ?? {};
  } else {
    return undefined;
  }
}

export function useDraftWorksheetHeaders(
  userId: string,
): Record<WorksheetId, WorksheetHeader> {
  const pNode = useFbPNode<Record<string, WorksheetHeader> | undefined>(
    `/draft_worksheet_headers/${userId}`,
  );
  const value = usePNode(pNode)[0];
  if (value !== undefined) {
    return value;
  } else {
    return {};
  }
}

export function useDraftWorksheetHeaderNode(
  userId: string,
  worksheetId: string,
): FbPNode<WorksheetHeader | undefined>;

export function useDraftWorksheetHeaderNode(
  userId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useDraftWorksheetHeaderNode(
  userId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useDraftWorksheetHeaderNode(
  userId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useDraftWorksheetHeaderNode(
  userId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<WorksheetHeader | undefined> | undefined;

export function useDraftWorksheetHeaderNode(
  userId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<WorksheetHeader | undefined> | undefined {
  return useFbPNode<WorksheetHeader | undefined>(
    userId != null && worksheetId != null
      ? `/draft_worksheet_headers/${userId}/${worksheetId}`
      : undefined,
  );
}

export function useDraftWorksheetNode(
  userId: string,
  worksheetId: string,
): FbPNode<FbWorksheet | undefined>;

export function useDraftWorksheetNode(
  userId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useDraftWorksheetNode(
  userId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useDraftWorksheetNode(
  userId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useDraftWorksheetNode(
  userId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<FbWorksheet | undefined> | undefined;

export function useDraftWorksheetNode(
  userId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<FbWorksheet | undefined> | undefined {
  return useFbPNode<FbWorksheet | undefined>(
    getDraftWorksheetPath(userId, worksheetId),
  );
}

export function getDraftWorksheetHeaderPath(
  userId: string,
  worksheetId: string,
): string;

export function getDraftWorksheetHeaderPath(
  userId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getDraftWorksheetHeaderPath(
  userId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getDraftWorksheetHeaderPath(
  userId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getDraftWorksheetHeaderPath(
  userId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined {
  return userId != null && worksheetId != null
    ? `/draft_worksheet_headers/${userId}/${worksheetId}`
    : undefined;
}

export function getDraftWorksheetPath(
  userId: string,
  worksheetId: string,
): string;

export function getDraftWorksheetPath(
  userId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getDraftWorksheetPath(
  userId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getDraftWorksheetPath(
  userId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getDraftWorksheetPath(
  userId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined;

export function getDraftWorksheetPath(
  userId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined {
  return userId != null && worksheetId != null
    ? `/draft_worksheets/${userId}/${worksheetId}`
    : undefined;
}

export function useUnofficialWorksheetNode(
  userId: string | undefined,
  courseId: string | undefined,
  worksheetId: string | undefined,
): FbPNode<FbWorksheet | undefined> | undefined;

export function useUnofficialWorksheetNode(
  userId: string,
  courseId: string,
  worksheetId: string,
): FbPNode<FbWorksheet | undefined>;

export function useUnofficialWorksheetNode(
  userId: string | undefined,
  courseId: string | undefined,
  worksheetId: string | undefined,
): FbPNode<FbWorksheet | undefined> | undefined {
  return useFbPNode<FbWorksheet | undefined>(
    userId != null && courseId != null && worksheetId != null
      ? getUnofficialWorksheetPath(userId, courseId, worksheetId)
      : undefined,
  );
}

export function getUnofficialWorksheetPath(
  userId: string,
  courseId: string,
  worksheetId: string,
): string {
  return `/unofficial_worksheets/${userId}/${courseId}/${worksheetId}`;
}

export function useUnofficialWorksheetHeaderNode(
  userId: string,
  courseId: string,
  worksheetId: string,
): FbPNode<WorksheetHeader | undefined> {
  return useFbPNode<WorksheetHeader | undefined>(
    getUnofficialWorksheetHeaderPath(userId, courseId, worksheetId),
  );
}

export function getUnofficialWorksheetHeaderPath(
  userId: string,
  courseId: string,
  worksheetId: string,
): string {
  return `/unofficial_worksheet_headers/${userId}/${courseId}/${worksheetId}`;
}

export function getUnofficialWorksheetHeadersPath(
  userId: string,
  courseId: string,
): string {
  return `/unofficial_worksheet_headers/${userId}/${courseId}`;
}

export function useUnofficialWorksheetHeadersNode(
  userId: string,
  courseId: string,
): FbPNode<Record<string, WorksheetHeader>> | undefined {
  return useFbPNode<Record<string, WorksheetHeader>>(
    getUnofficialWorksheetHeadersPath(userId, courseId),
  );
}

export function useAnyWorksheetNode(
  uid: string,
  schoolId: string,
  courseId: string,
  worksheetId: string,
) {
  return useAnyWorksheetNodeAndPath({ uid, schoolId, courseId, worksheetId })
    .node;
}

export type WorksheetType = "official" | "unofficial" | "clazz";

export function useAnyWorksheetNodeAndPath({
  uid,
  schoolId,
  courseId,
  worksheetId,
  draftWorksheetId,
}: { uid: string } & (
  | {
      schoolId: string;
      courseId: string;
      worksheetId: string;
      draftWorksheetId?: undefined;
    }
  | {
      schoolId?: undefined;
      courseId?: undefined;
      worksheetId?: undefined;
      draftWorksheetId: string;
    }
)) {
  const worksheetNode = useWorksheetNode(schoolId, courseId, worksheetId);
  const draftWorksheetNode = useDraftWorksheetNode(uid, draftWorksheetId);
  const courseClazzWorksheetsNode = useCourseClazzWorksheetsNode(courseId);
  const unofficialWorksheetNode = useUnofficialWorksheetNode(
    uid,
    courseId,
    worksheetId,
  );

  const [officialExists] = usePNode(worksheetNode, {
    reviver: (worksheet) => worksheet !== undefined,
  });

  const [worksheetClazzId] = usePNode(courseClazzWorksheetsNode, {
    reviver: (courseClazzWorksheets) => {
      if (courseClazzWorksheets !== undefined) {
        return Object.keys(courseClazzWorksheets).find(
          (clazzId) => courseClazzWorksheets[clazzId][worksheetId!] != null,
        );
      } else {
        return undefined;
      }
    },
  });
  const [draftExists] = usePNode(draftWorksheetNode, {
    reviver: (draftWorksheet) => draftWorksheet !== undefined,
  });

  const [unofficialExists] = usePNode(unofficialWorksheetNode, {
    reviver: (unofficialWorksheet) => unofficialWorksheet != undefined,
  });

  if (officialExists) {
    const path = getWorksheetPath(schoolId, courseId!, worksheetId!);
    return {
      node: worksheetNode as PNode<FbWorksheet>,
      path,
      headerPath: worksheetPathToWorksheetHeaderPath(path),
      type: "official" as const,
    };
  } else if (draftExists) {
    const path = getDraftWorksheetPath(uid, draftWorksheetId!);
    return {
      node: draftWorksheetNode as PNode<FbWorksheet>,
      path,
      headerPath: worksheetPathToWorksheetHeaderPath(path),
      type: "unofficial" as const,
    };
  } else if (worksheetClazzId !== undefined) {
    const path = getClazzWorksheetPath(
      courseId!,
      worksheetClazzId!,
      worksheetId!,
    );
    return {
      node: courseClazzWorksheetsNode!
        .child(worksheetClazzId)
        .child(worksheetId!) as PNode<FbWorksheet>,
      path,
      headerPath: worksheetPathToWorksheetHeaderPath(path),
      type: "clazz" as const,
    };
  } else if (unofficialExists) {
    const path = getUnofficialWorksheetPath(uid, courseId!, worksheetId!);
    return {
      node: unofficialWorksheetNode as PNode<FbWorksheet>,
      path,
      headerPath: worksheetPathToWorksheetHeaderPath(path),
      type: "unofficial" as const,
    };
  } else {
    throw new NotFoundError();
  }
}

export function useClazzWorksheetHeaderNode(
  courseId: string,
  clazzId: string,
  worksheetId: string,
): FbPNode<WorksheetHeader | undefined>;

export function useClazzWorksheetHeaderNode(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetHeaderNode(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetHeaderNode(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetHeaderNode(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useClazzWorksheetHeaderNode(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetHeaderNode(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useClazzWorksheetHeaderNode(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useClazzWorksheetHeaderNode(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<FbWorksheet | undefined> | undefined;

export function useClazzWorksheetHeaderNode(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<WorksheetHeader | undefined> | undefined {
  return useFbPNode<WorksheetHeader | undefined>(
    getClazzWorksheetHeaderPath(courseId, clazzId, worksheetId),
  );
}

export function getClazzWorksheetHeaderPath(
  courseId: string,
  clazzId: string,
  worksheetId: string,
): string;

export function getClazzWorksheetHeaderPath(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetHeaderPath(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetHeaderPath(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetHeaderPath(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getClazzWorksheetHeaderPath(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetHeaderPath(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getClazzWorksheetHeaderPath(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getClazzWorksheetHeaderPath(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined;

export function getClazzWorksheetHeaderPath(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined {
  return courseId != null && clazzId != null && worksheetId != null
    ? `/clazz_worksheet_headers/${courseId}/${clazzId}/${worksheetId}`
    : undefined;
}

export function useClazzWorksheetNode(
  courseId: string,
  clazzId: string,
  worksheetId: string,
): FbPNode<FbWorksheet | undefined>;

export function useClazzWorksheetNode(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetNode(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetNode(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetNode(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useClazzWorksheetNode(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useClazzWorksheetNode(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useClazzWorksheetNode(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useClazzWorksheetNode(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<FbWorksheet | undefined> | undefined;

export function useClazzWorksheetNode(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<FbWorksheet | undefined> | undefined {
  return useFbPNode<FbWorksheet | undefined>(
    getClazzWorksheetPath(courseId, clazzId, worksheetId),
  );
}

export function getClazzWorksheetPath(
  courseId: string,
  clazzId: string,
  worksheetId: string,
): string;

export function getClazzWorksheetPath(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetPath(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetPath(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetPath(
  courseId: null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getClazzWorksheetPath(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getClazzWorksheetPath(
  courseId: string | null | undefined,
  clazzId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getClazzWorksheetPath(
  courseId: null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getClazzWorksheetPath(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined;

export function getClazzWorksheetPath(
  courseId: string | null | undefined,
  clazzId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined {
  return courseId != null && clazzId != null && worksheetId != null
    ? `/clazz_worksheets/${courseId}/${clazzId}/${worksheetId}`
    : undefined;
}

export function useCourseClazzWorksheetHeadersNode(
  courseId: string,
): FbPNode<Record<string, Record<string, WorksheetHeader>> | undefined>;

export function useCourseClazzWorksheetHeadersNode(
  courseId: null | undefined,
): undefined;

export function useCourseClazzWorksheetHeadersNode(
  courseId: string | null | undefined,
):
  | FbPNode<Record<string, Record<string, WorksheetHeader>> | undefined>
  | undefined;

export function useCourseClazzWorksheetHeadersNode(
  courseId: string | null | undefined,
):
  | FbPNode<Record<string, Record<string, WorksheetHeader>> | undefined>
  | undefined {
  return useFbPNode<
    Record<string, Record<string, WorksheetHeader>> | undefined
  >(getCourseClazzWorksheetHeadersPath(courseId));
}

export function getCourseClazzWorksheetHeadersPath(courseId: string): string;

export function getCourseClazzWorksheetHeadersPath(
  courseId: null | undefined,
): string;

export function getCourseClazzWorksheetHeadersPath(
  courseId: string | null | undefined,
): string | undefined;

export function getCourseClazzWorksheetHeadersPath(
  courseId: string | null | undefined,
): string | undefined {
  return courseId != null ? `/clazz_worksheet_headers/${courseId}` : undefined;
}

export function useCourseClazzWorksheetsNode(
  courseId: string,
): FbPNode<Record<string, Record<string, FbWorksheet>> | undefined>;

export function useCourseClazzWorksheetsNode(
  courseId: null | undefined,
): undefined;

export function useCourseClazzWorksheetsNode(
  courseId: string | null | undefined,
): FbPNode<Record<string, Record<string, FbWorksheet>> | undefined> | undefined;

export function useCourseClazzWorksheetsNode(
  courseId: string | null | undefined,
):
  | FbPNode<Record<string, Record<string, FbWorksheet>> | undefined>
  | undefined {
  return useFbPNode<Record<string, Record<string, FbWorksheet>> | undefined>(
    courseId != null ? `/clazz_worksheets/${courseId}` : undefined,
  );
}

export function useWorksheetHeader(
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
): [WorksheetHeader | undefined, (value: WorksheetHeader) => void];

export function useWorksheetHeader(
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
): [WorksheetHeader | undefined, (value: WorksheetHeader) => void] {
  const [value, setValue] = usePNode(
    useWorksheetHeaderNode(courseId, worksheetId),
  );
  return [value, setValue];
}

export function useWorksheetHeaderNode(
  courseId: string,
  worksheetId: string,
): FbPNode<WorksheetHeader | undefined>;

export function useWorksheetHeaderNode(
  courseId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useWorksheetHeaderNode(
  courseId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function useWorksheetHeaderNode(
  courseId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function useWorksheetHeaderNode(
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<WorksheetHeader | undefined> | undefined;

export function useWorksheetHeaderNode(
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<WorksheetHeader | undefined> | undefined {
  return useFbPNode<WorksheetHeader | undefined>(
    getWorksheetHeaderPath(courseId, worksheetId),
  );
}

export function getWorksheetHeaderPath(
  courseId: string,
  worksheetId: string,
): string;

export function getWorksheetHeaderPath(
  courseId: null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getWorksheetHeaderPath(
  courseId: string | null | undefined,
  worksheetId: null | undefined,
): undefined;

export function getWorksheetHeaderPath(
  courseId: null | undefined,
  worksheetId: string | null | undefined,
): undefined;

export function getWorksheetHeaderPath(
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined;

export function getWorksheetHeaderPath(
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
): string | undefined {
  return courseId != null && worksheetId != null
    ? `/worksheet_headers/${courseId}/${worksheetId}`
    : undefined;
}

export function useWorksheet(
  schoolId: string,
  courseId: string,
  worksheetId: string,
) {
  return useFbValue<FbWorksheet>(
    getWorksheetPath(schoolId, courseId, worksheetId),
  );
}

interface WorksheetMetadata {
  blank?: string;
  solution?: string;
}

export type WorksheetSync = {
  state?: FbWorksheetState;
  chat_sessions?: Record<string, WorksheetChatSession>;
  last_active?: number;
};

export function useWorksheetMetadata(worksheetId: string) {
  return useFbValue<WorksheetMetadata | undefined>(
    `/worksheet_metadata/${worksheetId}`,
  );
}

export function useWorksheetMetadataNode(worksheetId: string | undefined) {
  return useFbPNode<WorksheetMetadata | undefined>(
    worksheetId !== undefined
      ? `/worksheet_metadata/${worksheetId}`
      : undefined,
  );
}

export function useWorksheetNode(
  schoolId: string,
  courseId: string,
  worksheetId: string,
): FbPNode<FbWorksheet | undefined>;

export function useWorksheetNode(
  schoolId: string | null | undefined,
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
): FbPNode<FbWorksheet | undefined> | undefined;

export function useWorksheetNode(
  schoolId: string | null | undefined,
  courseId: string | null | undefined,
  worksheetId: string | null | undefined,
) {
  return useFbPNode<FbWorksheet | undefined>(
    schoolId != null && courseId != null && worksheetId != null
      ? getWorksheetPath(schoolId, courseId, worksheetId)
      : null,
  );
}

export function useWorksheetStateNode(userId: string, worksheetId: string) {
  return useFbPNode<FbWorksheetState>(
    getWorksheetStatePath(userId, worksheetId),
  );
}

export function getWorksheetPath(
  schoolId: string | undefined,
  courseId: string,
  worksheetId: string,
) {
  return `/worksheets/${courseId}/${worksheetId}`;
}

export function getWorksheetSyncPath(userId: string, worksheetId: string) {
  return `/userWorksheets/${userId}/${worksheetId}`;
}

export function getWorksheetStatePath(userId: string, worksheetId: string) {
  return `${getWorksheetSyncPath(userId, worksheetId)}/state`;
}

export function worksheetHeaderCompareFn(
  a: WorksheetHeader,
  b: WorksheetHeader,
) {
  if (a.category !== b.category) {
    return (b.category ?? "").localeCompare(a.category ?? "", "en-US");
  }

  const aTerm = new AcademicTerm(a.year ?? 0, a.season ?? "winter");
  const bTerm = new AcademicTerm(b.year ?? 0, b.season ?? "winter");

  if (+aTerm !== +bTerm) {
    return +bTerm - +aTerm;
  }

  const aInstructors = (a.instructors ?? []).sort().join(", ");
  const bInstructors = (b.instructors ?? []).sort().join(", ");

  if (aInstructors !== bInstructors) {
    return aInstructors.localeCompare(bInstructors, "en-US");
  }

  return (a.name ?? "").localeCompare(b.name ?? "", "en-US");
}

export function worksheetTermComparator(
  a: { year: number; season: AcademicSeason },
  b: { year: number; season: AcademicSeason },
) {
  const aTerm = new AcademicTerm(a.year ?? 0, a.season ?? "winter");
  const bTerm = new AcademicTerm(b.year ?? 0, b.season ?? "winter");

  return +aTerm - +bTerm;
}
