import {
  getLocalStorageItem,
  getSessionStorageItem,
  setSessionStorageItem,
  useSessionStorage,
} from "../foundation/local-storage";
import { useFbPNode } from "../foundation/p-node/firebase";

export type InstitutionType = "high_school" | "higher_education";
export type IntendedUsage = "homework" | "exam_prep" | "both";

export type HighSchoolOnboardingData = {
  institutionType: "high_school";
};

export type HigherEducationOnboardingData = {
  institutionType: "higher_education";
  intendedUsage: IntendedUsage;
  selectedSchoolId: string;
  selectedCourseIds: string[];
  newCourseNames: string[];
};
export type OnboardingData =
  | HighSchoolOnboardingData
  | HigherEducationOnboardingData;

export function getOnboardingDataPath(uid: string) {
  return `/onboarding_data/${uid}`;
}

export function useOnboardingDataNode(uid: string) {
  return useFbPNode<OnboardingData | undefined>(getOnboardingDataPath(uid));
}

export function useSessionOnboardingData() {
  return useSessionStorage<Partial<OnboardingData>>("onboarding_data", {});
}

export function getSessionOnboardingData() {
  return getSessionStorageItem("onboarding_data") ?? {};
}

export function setSessionOnboardingData(data: Partial<OnboardingData>) {
  setSessionStorageItem("onboarding_data", data);
}

export function isValidOnboardingData(
  data: Partial<OnboardingData>,
): data is OnboardingData {
  if (data.institutionType === "high_school") {
    return true;
  }

  if (
    data.institutionType === "higher_education" &&
    data.intendedUsage &&
    data.selectedSchoolId &&
    data.selectedCourseIds != null &&
    data.newCourseNames != null &&
    data.selectedCourseIds.length + data.newCourseNames.length > 0
  ) {
    return true;
  }

  return false;
}
