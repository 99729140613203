import { env } from "@/env";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  ParsedToken,
  User,
  browserLocalPersistence,
  getAuth,
  getRedirectResult,
  initializeAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  revokeAccessToken,
  signInWithCredential,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import {
  QueryConstraint,
  Unsubscribe,
  getDatabase,
  onValue,
  query,
  ref,
} from "firebase/database";
import { useCallback, useRef, useSyncExternalStore } from "react";
import { ampl } from "./amplitude";
import { ListenableNotifier } from "./listenable";
import { deepEqual } from "fast-equals";
import { useSuspenseQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { queue } from "async";
import { Capacitor } from "@capacitor/core";
import FirebasePlugin from "./capacitor/firebase-plugin";

export const fbApp = initializeApp(env.firebaseConfig);
export const fbDb = getDatabase(fbApp);

export const fbAuth = Capacitor.isNativePlatform()
  ? initializeAuth(fbApp, {
      persistence: browserLocalPersistence,
    })
  : getAuth(fbApp);

export function fbDbRef(path: string) {
  return ref(fbDb, path);
}

export function useFbUser() {
  return useFbUserState().user;
}

export function useFbUserClaims() {
  return useFbUserState().claims;
}

export function useFbUserState() {
  useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["fbUserState", "loader"],
    queryFn: () => {
      return new Promise<null>((resolve) => {
        if (!getFbUserState().isLoading) {
          resolve(null);
          return;
        }

        const unsubscribe = subscribeToUserNotifier(() => {
          if (!getFbUserState().isLoading) {
            unsubscribe();
            resolve(null);
          }
        });
      });
    },
  });

  return useSyncExternalStore(subscribeToUserNotifier, () => getFbUserState());
}

export function useFbQuery<T, U>(
  path: string,
  options: {
    queryConstraints: QueryConstraint[];
    map: (value: T | null) => U;
  },
) {
  const dataRef = useRef<U>(options.map(null));
  const isLoadingRef = useRef(true);
  const errorRef = useRef<Error | null>(null);

  const subscribe = useCallback(
    (onChange: () => void) => {
      const r = fbDbRef(path);
      const q = query(r, ...options.queryConstraints);

      const unsubscribe = onValue(
        q,
        (snapshot) => {
          const oldData = dataRef.current;
          dataRef.current = options.map(snapshot.val());
          isLoadingRef.current = false;
          errorRef.current = null;

          if (!deepEqual(oldData, dataRef.current)) {
            onChange();
          }
        },
        (error) => {
          dataRef.current = options.map(null);
          errorRef.current = error;
          isLoadingRef.current = false;
        },
      );

      return unsubscribe;
    },
    [path, options],
  );

  const data = useSyncExternalStore(
    subscribe,
    () => dataRef.current,
    () => dataRef.current,
  );

  return {
    data: data,
    isLoading: isLoadingRef.current,
    error: errorRef.current,
  };
}

export function getFbUser() {
  return fbUserState;
}

export function getFbUserPromise() {
  return new Promise<User | null>((resolve) => {
    const data = getFbUser();

    if (!data.isLoading) {
      resolve(data.user);
      return;
    }

    const unsubscribe = subscribeToUserNotifier(() => {
      const data = getFbUser();

      if (!data.isLoading) {
        resolve(data.user);
        unsubscribe();
      }
    });
  });
}

export function getFbUserClaimsPromise() {
  return new Promise<ParsedToken | null>((resolve) => {
    const data = getFbUser();

    if (!data.isLoading) {
      resolve(data.claims);
      return;
    }

    const unsubscribe = subscribeToUserNotifier(() => {
      const data = getFbUser();

      if (!data.isLoading) {
        resolve(data.claims);
        unsubscribe();
      }
    });
  });
}

export async function signInWithGoogle() {
  try {
    if (Capacitor.isNativePlatform()) {
      const credentials = await FirebasePlugin.getGoogleSignInCredentials({
        clientId: env.firebaseAppleClientId,
      });

      return await signInWithCredential(
        fbAuth,
        GoogleAuthProvider.credential(
          credentials.idToken,
          credentials.accessToken,
        ),
      );
    } else {
      const provider = new GoogleAuthProvider();

      provider.setCustomParameters({ prompt: "select_account" });

      if (location.hostname == "localhost") {
        return await signInWithPopup(fbAuth, provider);
      }

      return await signInWithRedirect(fbAuth, provider);
    }
  } catch (error) {
    if (error instanceof Error && error.message.includes("canceled")) {
      return;
    }
    throw error;
  }
}

export async function signInWithApple() {
  try {
    if (Capacitor.isNativePlatform()) {
      const credentials = await FirebasePlugin.getAppleSignInCredentials();

      const provider = new OAuthProvider("apple.com");

      const credential = provider.credential({
        idToken: credentials.identityToken,
        rawNonce: credentials.nonce,
      });

      return await signInWithCredential(fbAuth, credential);
    } else {
      const provider = new OAuthProvider("apple.com");
      return await signInWithRedirect(fbAuth, provider);
    }
  } catch (error) {
    if (error instanceof Error && error.message.includes("1001")) {
      return;
    }
    throw error;
  }
}

export async function revokeAppleAuth() {
  if (Capacitor.getPlatform() !== "ios") {
    throw new Error("Only available for iOS");
  }

  const result = await signInWithApple();

  await revokeAccessToken(fbAuth, (result as any)._tokenResponse.refreshToken);
}

export async function getSignInProvider() {
  const result = await (await getFbUserPromise())?.getIdTokenResult();
  return result?.signInProvider ?? undefined;
}

function subscribeToUserNotifier(callback: () => void) {
  return fbUserNotifier.subscribe(callback);
}

export function getFbUserNotifier() {
  return fbUserNotifier;
}

function getFbUserState() {
  return fbUserState;
}

const fbUserNotifier = new ListenableNotifier();

let fbUserState = {
  user: null as User | null,
  claims: null as ParsedToken | null,
  isLoading: true,
};

let lastGetTokenResultTime = 0;

let lastTokenUpdateSubscription: Unsubscribe | undefined = undefined;

function subscribeToLastTokenUpdate() {
  if (lastTokenUpdateSubscription != null) {
    lastTokenUpdateSubscription();
  }

  if (fbUserState.user == null) return;

  lastTokenUpdateSubscription = onValue(
    fbDbRef(`last_token_update/${fbUserState.user.uid}`),
    (snapshot) => {
      const lastTokenUpdate = (snapshot.val() ?? 0) as number;

      if (
        lastTokenUpdate >
        (fbUserState.claims?.iat as unknown as number) * 1000
      ) {
        fbUserState.user?.getIdToken(true).catch((error) => {
          console.error(error);
        });
      }
    },
    (error) => {
      console.error(error);
    },
  );
}

const idTokenChangedQueue = queue<User | null>(async (user) => {
  if (typeof window === "undefined") return;

  if (!fbUserState.isLoading && fbUserState.user != null && user == null) {
    ampl.track("Logged Out");
  }

  ampl.setUserId(user?.uid ?? undefined);
  Sentry.setUser(
    user != null ? { id: user.uid, email: user.email ?? undefined } : null,
  );

  const identify = new ampl.Identify();
  if (user?.email) identify.set("Email", user.email);
  if (user?.displayName) identify.set("Display Name", user.displayName);

  ampl.identify(identify);

  if (!fbUserState.isLoading && fbUserState.user == null && user != null) {
    ampl.track("Logged In");
  }

  if (!Capacitor.isNativePlatform()) {
    const result = await getRedirectResult(fbAuth);
    console.log("result", result);
  }

  if (user == null) {
    fbUserState = {
      user: null,
      claims: null,
      isLoading: false,
    };
    fbUserNotifier.notify();
  } else {
    const getTokenResultTime = Date.now();
    user
      .getIdTokenResult()
      .then((tokenResult) => {
        if (getTokenResultTime >= lastGetTokenResultTime) {
          fbUserState = {
            user: user,
            claims: tokenResult.claims,
            isLoading: false,
          };
          lastGetTokenResultTime = getTokenResultTime;
          fbUserNotifier.notify();
          subscribeToLastTokenUpdate();
        }
      })
      .catch((error) => {
        console.error(error);
        if (getTokenResultTime >= lastGetTokenResultTime) {
          fbUserState = {
            user: null,
            claims: null,
            isLoading: false,
          };
          lastGetTokenResultTime = getTokenResultTime;
          fbUserNotifier.notify();
        }
      });
  }
}, 1);

onIdTokenChanged(fbAuth, (user) => {
  idTokenChangedQueue.push(user);
});
