import { registerPlugin } from "@capacitor/core";

interface IntercomPlugin {
  registerIdentifiedUser(options: {
    userId?: string;
    email?: string;
  }): Promise<void>;
  setUserHash(options: { hmac: string }): Promise<void>;
  logout(): Promise<void>;
  displayMessenger(): Promise<void>;
  setApiKey(options: { apiKey: string; appId: string }): Promise<void>;
}

const IntercomPlugin = registerPlugin<IntercomPlugin>("Intercom");

export default IntercomPlugin;
