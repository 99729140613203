import { AppBarContainer } from "@/lib/meat/app-bar/app-bar";
import { UnauthorizedScreen } from "@/lib/meat/error-screen";
import { useIsRole } from "@/lib/meat/roles";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/live-support")({
  component: () => <Wrapper />,
});

function Wrapper() {
  if (!useIsRole("pensieve")) {
    return (
      <AppBarContainer>
        <UnauthorizedScreen />
      </AppBarContainer>
    );
  }

  return <Outlet />;
}
