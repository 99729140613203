import { createFileRoute } from "@tanstack/react-router";

export type EditWorksheetSearch = {
  worksheetPath: string;
};

export const Route = createFileRoute("/_auth/edit-worksheet/")({
  validateSearch: (search: Record<string, unknown>): EditWorksheetSearch => {
    return { worksheetPath: search.worksheetPath as string };
  },
});

